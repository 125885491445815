import axios from "@/axios";
const state = {
  doctors: {},
  financePackagesList: [],
};

const getters = {
  allDoctors: (state) => state.doctors.data,
  getDoctor: (state) => (id) => {
    var doctor = state.doctors.data.find((doctor) => doctor.id === id);
    return doctor;
  },
  getDoctorCurrentPage: (state) => state.doctors.current_page,
  getDoctorLastPage: (state) => state.doctors.last_page,
  getFinancePackagesList: (state) => state.financePackagesList,
};
const actions = {
  async fetchDoctors({ commit }, info) {
    const response = await axios.get(
      "/admin/users?per_page=10&&page=" + info.page + "&&search=" + info.keyword
    );
    commit("SET_DOCTORS", response.data);
  },

  setDoctorCurrentPage({ commit }, currentPage) {
    commit("SET_DOCTOR_CURRENT_PAGE", currentPage);
  },
  setDoctorLastPage({ commit }, lastPage) {
    commit("SET_DOCTOR_LAST_PAGE", lastPage);
  },
  async fetchFinancePackages({ commit }) {
    const response = await axios.get("/admin/financePackages");
    commit("SET_FINANCE_PACKAGES", response.data);
  },
  async activateSubscription({ commit }, info) {
    var response = await axios.post(
      "/admin/users/" + info.get("userId") + "/activateSubscription",
      info
    );
    if (response.status === 201 || response.status === 200) {
      commit("ACTIVATE_DOCTOR_SUBSCRIPTION", response.data.data);
    }
  },
  async addNewDoctor({ commit }, doctor) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    var newDoctor = await axios.post(
      "/admin/clinics/" + doctor.get("clinic_id") + "/users",
      doctor
    );
    console.log(newDoctor.data);
    if (newDoctor.status === 201) commit("ADD_DOCTOR", newDoctor.data.data);
  },
  async addNewClinicAssistant({ commit }, clinicAssistant) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    var response = await axios.post(
      "/admin/clinics/" +
        clinicAssistant.get("clinic_id") +
        "/users/clinicAssistant",
      clinicAssistant
    );
    console.log(response.data);
    if (response.status === 201) commit("ADD_DOCTOR", response.data.data);
  },
  async addNewPharmacyUser({ commit }, pharmacyUser) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    var response = await axios.post(
      "/admin/clinics/" + pharmacyUser.get("clinic_id") + "/users/pharmacyUser",
      pharmacyUser
    );
    console.log(response.data);
    if (response.status === 201) commit("ADD_DOCTOR", response.data.data);
  },
  async updateDoctorData({ commit }, doctorForm) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/admin/doctors/" + doctorForm.get("doctorId"),
      doctorForm
    );

    if (response.data.message == "updated")
      commit("UPDATE_DOCTOR", response.data.data);
    return response.data.message;
  },
  async deleteDoctor({ commit }, id) {
    const response = await axios.post("/admin/doctors/users/" + id, {
      _method: "delete",
    });
    if (response.data.message == "deleted") {
      commit("DELETE_DOCTOR", id);
      return "deleted";
    } else return "failed";
  },
};
const mutations = {
  SET_DOCTORS: (state, data) => (state.doctors = data),
  SET_FINANCE_PACKAGES: (state, data) => (state.financePackagesList = data),
  SET_DOCTOR_CURRENT_PAGE: (state, data) => (state.doctors.current_page = data),
  SET_DOCTOR_LAST_PAGE: (state, data) => (state.doctors.last_page = data),
  ACTIVATE_DOCTOR_SUBSCRIPTION: (state, data) => {
    const i = state.doctors.data
      .map((item) => item.id)
      .indexOf(parseInt(data.user_id, 10));
    state.doctors.data[i].subscription = data;
    state.doctors.data[i].subscriptionStatus = data.package_details.name;
  },
  ADD_DOCTOR: (state, data) => {
    state.doctors.data.unshift(data);
  },
  UPDATE_DOCTOR: (state, data) => {
    const i = state.doctors.data.map((item) => item.id).indexOf(data.user_id);

    state.doctors.data[i].doctor.doctor_specializations =
      data.doctor_specializations;

    state.doctors.data[i].fullname = data.user_info.fullname;
    state.doctors.data[i].username = data.user_info.username;
    state.doctors.data[i].profile_picture = data.user_info.profile_picture;
    state.doctors.data[i].address = data.user_info.address;
    state.doctors.data[i].mobile = data.user_info.mobile;
    state.doctors.data[i].doctor.fees = data.fees;
  },
  DELETE_DOCTOR: (state, id) => {
    const i = state.doctors.data.map((item) => item.id).indexOf(id);
    state.doctors.data.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
