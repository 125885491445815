<template>
  <v-dialog
    :value="editDoctorDialog"
    v-if="editDoctorDialog"
    width="600"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3 grey lighten-4">
        <h5 class="text-right pr-1">
          تعديل معلومات الطبيب
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-form ref="edit_doctor_form" lazy-validation>
          <v-col cols="12" md="8" lg="6" class="text-center mx-auto">
            <v-avatar size="65">
              <img :src="doctorData.profile_picture" alt="doctor profile pic" />
            </v-avatar>
            <v-file-input
              v-model="profilePicture"
              accept="image/png, image/jpeg, image/jpg"
              placeholder="اختيار صورة جديدة"
              prepend-icon="mdi-camera"
              label="صورة جديدة"
            ></v-file-input>
          </v-col>
          <v-col cols="12"
            ><v-text-field
              label="اسم الطبيب"
              :rules="requiredRule"
              v-model="doctorData.fullname"
            >
            </v-text-field
          ></v-col>

          <v-col cols="12">
            <v-text-field
              v-model="doctorData.username"
              placeholder="اسم المستخدم"
              :rules="requiredRule"
              label="اسم المستخدم"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="doctorData.mobile"
              placeholder="رقم الهاتف"
              label="رقم الهاتف"
              :rules="mobileRule"
              maxlength="10"
              minlength="10"
              suffix="964+"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="doctorData.role == 'doctor'">
            <v-text-field
              type="number"
              label="الاجور"
              v-model="doctorData.doctor.fees"
              :rules="requiredRule"
              suffix="دينار عراقي"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="doctorData.role == 'doctor'">
            <v-select
              :rules="requiredRule"
              v-model="doctorData.doctor.doctor_specializations"
              item-text="name"
              item-value="id"
              :items="getSpecializations"
              label="اختر التخصص"
              multiple
              chips
              hint="يمكنك اختيار اكثر من تخصص"
              persistent-hint
              required
            >
            </v-select>
          </v-col>
        </v-form>
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية التحديث تمت بنجاح
        </v-alert>
        <v-alert dense outlined type="warning" v-if="duplicateAlrt">
          اسم المستخدم محجوز يرجى اختيار اسم اخر
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="updateDoctor"
          large
        >
          تحديث
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <!-- <v-snackbar v-model="snackbar">
      الرجاء اختيار اليوم والتوقيت

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["editDoctorDialog", "id"],
  data() {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      duplicateAlrt: false,
      DoctorForm: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      // snackbar: false,
      activePicker: null,
      profilePicture: null,
    };
  },
  created() {
    this.fetchSpecializations();
  },
  computed: {
    ...mapGetters(["getSpecializations", "getDoctor"]),
    doctorData() {
      return this.getDoctor(this.id);
    },
  },

  methods: {
    ...mapActions(["updateDoctorData", "fetchSpecializations"]),
    closeDialog() {
      this.DoctorForm = new FormData();
      this.$emit("editDoctorDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },

    updateDoctor() {
      if (!this.$refs.edit_doctor_form.validate()) return;
      this.loadingSpinner = true;
      this.DoctorForm.append("fullname", this.doctorData.fullname);
      this.DoctorForm.append("username", this.doctorData.username);
      this.DoctorForm.append("mobile", this.doctorData.mobile);
      if (this.doctorData.role == "doctor") {
        this.DoctorForm.append("fees", this.doctorData.doctor.fees);
        this.DoctorForm.append(
          "specializations",
          JSON.stringify(this.doctorData.doctor.doctor_specializations)
        );
        this.DoctorForm.append("doctorId", this.doctorData.doctor.id);
      }

      this.DoctorForm.append("profilePicture", this.profilePicture);

      this.DoctorForm.append("_method", "PUT");

      this.updateDoctorData(this.DoctorForm)
        .then((msg) => {
          if (msg == "updated") {
            this.loadingSpinner = false;
            this.successAlrt = true;
            this.errorAlrt = false;
            this.duplicateAlrt = false;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$emit("editDoctorDialogClose", false);
              // this.$refs.edit_doctor_form.reset();
              this.DoctorForm = new FormData();
            }, 3000);
          } else if (msg == "duplicate") {
            this.duplicateAlrt = true;
            this.loadingSpinner = false;
            this.successAlrt = false;
            this.errorAlrt = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.errorAlrt = true;
          this.loadingSpinner = false;
          this.duplicateAlrt = false;
          this.successAlrt = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
