<template>
  <v-dialog v-model="pharmacyUserDialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="indigo"
        style="letter-spacing: 0"
        class="float-right order-1 order-md-2 white--text ml-2 mb-2 d-inline-block"
      >
        <v-icon left dark>mdi-plus</v-icon>
         صيدلية جديدة
      </v-btn>
    </template>
    <v-card v-click-outside="handleClickOutside">
      <v-card-title>
        <span class="headline" style="font-family: 'cairo' !important"
          >معلومات الصيدلي </span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
            ref="pharmacy_user_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="PharmacyUser.fullname"
                  label="الاسم الكامل"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="PharmacyUser.username"
                  label="اسم المستخدم"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="password"
                  v-model="PharmacyUser.password"
                  label="كلمة السر"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="PharmacyUser.pharmacy_name"
                  label="اسم الصيدلية"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="PharmacyUser.gender"
                  :items="genders"
                  item-text="val"
                  item-value="id"
                  label="الجنس"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="PharmacyUser.mobile"
                  label="رقم الهاتف"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="text"
                  v-model="PharmacyUser.address"
                  label="العنوان"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="PharmacyUser.clinic_id"
                  @keyup="searchForClinic"
                  :search-input.sync="searchClinic"
                  :items="clinicList"
                  item-value="id"
                  label="اختر العيادة"
                  placeholder="بحث عن اسم العيادة"
                  required
                  :rules="requiredRule"
                  outlined
                  :item-text="clinicFormatText"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <!-- Add your alerts and buttons here -->
            <!-- Example: -->
            <v-alert dense text type="success" v-if="successAlrt">
              عملية الاضافة <strong>تمت بنجاح</strong>
            </v-alert>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              فشل في عملية الاضافة الرجاء اعادة المحاولة
            </v-alert>
            <v-alert dense outlined type="warning" v-if="duplicateAlrt">
              اسم المستخدم محجوز يرجى اختيار اسم اخر
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1 white--text"
          :loading="newPharmacyUserLoading"
          :disabled="newPharmacyUserLoading"
          @click="addPharmacyUser"
        >
          حفظ
        </v-btn>
        <v-btn color="black darken-1" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NewPharmacyUser",

  data() {
    return {
   
      pharmacyUserDialog: false,
      newPharmacyUserLoading: false,
      genders: [
        { id: 1, val: "ذكر" },
        { id: 2, val: "انثى" },
      ],
      newPharmacyUser: new FormData(),
      PharmacyUser: {
        clinic_id: 0,
        pharmacy_name:"",
        fullname: "",
        username: "",
        password: "",
        mobile: "",
        gender: 0,
        address: "",
      },
      searchClinic: "",
      // Add your existing data properties here if needed
      // Example:
      successAlrt: false,
      errorAlrt: false,
      duplicateAlrt: false,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
    };
  },
  computed: {
    ...mapGetters(["clinicList"]),
  },
  created() {
    // Fetch necessary data if needed
  },
  methods: {
    ...mapActions(["addNewPharmacyUser", "fetchClinicList"]),
    clinicFormatText: (item) => item.clinic_name + "-" + item.clinic_address,
    closeDialog() {
      // Reset your data properties here
      this.pharmacyUserDialog = false;
      this.newPharmacyUser = new FormData();
      // Reset additional properties if needed
      // Example:
      this.successAlrt = false;
      this.errorAlrt = false;
      this.duplicateAlrt = false;
      this.$refs.pharmacy_user_form.reset();
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    searchForClinic() {
      setTimeout(() => {
        if (this.searchClinic != "") this.fetchClinicList(this.searchClinic);
      }, 2000);
    },
    addPharmacyUser() {
      if (!this.$refs.pharmacy_user_form.validate()) return;
      this.newPharmacyUserLoading = true;
      this.duplicateAlrt = false;
      this.newPharmacyUser = new FormData();
      this.newPharmacyUser.append("pharmacy_name", this.PharmacyUser.pharmacy_name);
      this.newPharmacyUser.append("fullname", this.PharmacyUser.fullname);
      this.newPharmacyUser.append("username", this.PharmacyUser.username);
      this.newPharmacyUser.append("password", this.PharmacyUser.password);
      this.newPharmacyUser.append("mobile", this.PharmacyUser.mobile);
      this.newPharmacyUser.append("address", this.PharmacyUser.address);
      this.newPharmacyUser.append(
        "clinic_id",
        this.PharmacyUser.clinic_id
      );
      this.newPharmacyUser.append("gender", this.PharmacyUser.gender);

      this.addNewPharmacyUser(this.newPharmacyUser)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.newPharmacyUserLoading = false;

          this.PharmacyUser.pharmacy_name = "";
          this.PharmacyUser.fullname = "";
          this.PharmacyUser.username = "";
          this.PharmacyUser.password = "";
          this.PharmacyUser.mobile = "";
          this.PharmacyUser.address = "";
          this.PharmacyUser.gender = 0;
          this.PharmacyUser.clinic_id = 0;
          this.$refs.pharmacy_user_form.reset();

          setTimeout(() => {
            this.successAlrt = false;
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors) {
            if (err.response.data.errors.username)
              if (err.response.data.errors.username[0])
                this.duplicateAlrt = true;
          } else this.errorAlrt = true;
          this.newPharmacyUserLoading = false;
        });
    },
  },
};
</script>
