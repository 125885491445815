import axios from "@/axios";
const state = {
  users: []
};

const getters = {
  allUsersData: state => state.users.data,
  getCurrentPage: state => state.users.current_page,
  getLastPage: state => state.users.last_page,
  //get certain case
  getUsers: state => id => {
    var user = state.users.data.find(user => user.id === id);
    return user;
  }
};

const actions = {
  async fetchUsers({ commit }, page) {
    const response = await axios.get("/user/fetchUsers" + page);
    commit("SET_USERS", response.data);
  },
  async changePasswordByAdmin(_, info) {
    const response = await axios.post("/admin/users/"+info.id+"/changePassword", {
      newPassword:info.newPassword,
      confirmPassword:info.confirmPassword,
    });
    if (response.data.message == "updated") {
      return "updated";
    } else return "failed";
  },
  async changePassword(_, info) {
    const response = await axios.post("/auth/users/changePassword", info);
    if (response.data.message == "updated") {
      return "updated";
    } else return "failed";
  },
  setCurrentPage({ commit }, currentPage) {
    commit("SET_CURRENT_PAGE", currentPage);
  },
  setLastPage({ commit }, lastPage) {
    commit("SET_LAST_PAGE", lastPage);
  }
};
const mutations = {
  SET_USERS: (state, quantums) => (state.quantums = quantums),
  SET_CURRENT_PAGE: (state, data) => (state.quantums.current_page = data),
  SET_LAST_PAGE: (state, data) => (state.quantums.last_page = data)
};

export default {
  state,
  getters,
  actions,
  mutations
};
