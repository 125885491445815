<template>
  <v-dialog :value="DeleteAdviceDialog" persistent max-width="290">
    <v-card v-click-outside="handleClickOutSide" dir="rtl">
      <v-card-title class="headline">
        تاكيد عملية الحذف
      </v-card-title>
      <v-card-text>هل انت متاكد من انك تريد حذف النصيحة الطبية؟</v-card-text>
      <v-alert dense text type="success" v-if="successAlrt">
        عملية الحذف <strong>تمت بنجاح</strong>
      </v-alert>
      <v-alert dense outlined type="error" v-if="errorAlrt">
        فشل في عملية الحذف الرجاء اعادة المحاولة لاحقا
      </v-alert>
      <v-card-actions>
        <v-btn
          :loading="deleteLoading"
          :disabled="deleteLoading"
          color="red darken-1 white--text"
          @click="deleteAdviceFun"
          rounded
        >
          حذف
        </v-btn>

        <v-btn color="dark darken-1" text @click="closeDialog">
          اغلاق
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteAdvice",
  props: ["DeleteAdviceDialog", "id"],
  data() {
    return {
      dialog: false,
      deleteLoading: false,
      successAlrt: false,
      errorAlrt: false,
    };
  },
  methods: {
    ...mapActions(["deleteAdvice"]),
    closeDialog() {
      this.dialog = false;
      this.$emit("deleteAdviceDialogClose", this.dialog);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    deleteAdviceFun() {
      this.deleteLoading = true;
      this.deleteAdvice(this.id)
        .then(() => {
          this.deleteLoading = false;
          this.errorAlrt = false;
          this.successAlrt = true;
          setTimeout(() => {
            this.dialog = false;
            this.successAlrt = false;
            this.$emit("deleteAdviceDialogClose", this.dialog);
          }, 3500);
        })
        .catch(() => {
          this.deleteLoading = false;
          this.errorAlrt = true;
        });
    },
  },
};
</script>
