import axios from "@/axios";
const state = {
  token: "",
  userInfo: {}
};
const getters = {
  authenticated: state => state.token && state.userInfo,
  getUser: state => state.userInfo,
  getRole:state=>state.userInfo.role,
};
const actions = {
  async loginFun({ dispatch }, cred) {
    var obj = JSON.parse(cred);
    const response = await axios.post("/auth/login", {
      username: obj.username,
      password: obj.password
    });
    if (response.data.message == "invalid_username_or_password")
      return "incorrect";
    else if (response.data.message != "success") return "failed";
    return dispatch("attempt", response.data.token);
  },
  async attempt({ commit, state }, token) {
    if (token) commit("setToken", token);
    if (!state.token) return;
    try {
      const response = await axios.post("/auth/me");
      if (response.data.role != "admin"&&response.data.role != "superadmin") {
        return "unAuthorized";
      } else {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        commit("setUser", response.data);
        return "success";
      }
    } catch {
      console.log("err");
      commit("setToken", null);
      commit("setUser", null);
      return "error";
    }
  },
  signout({ commit }) {
    return axios.post("/auth/logout").then(() => {
      commit("setToken", null);
      commit("setUser", null);
      localStorage.removeItem("admin_token");
    });
  }
};
const mutations = {
  setToken: (state, admin_token) => (state.token = admin_token),
  setUser: (state, userInfo) => (state.userInfo = userInfo)
};
export default {
  state,
  getters,
  actions,
  mutations
};
