import axios from "@/axios";
const state = {
  specializations: []
};

const getters = {
  getSpecializations: state => state.specializations
};
const actions = {
  async fetchSpecializations({ commit }) {
    const response = await axios.get("/admin/specializations");
    commit("SET_SPECIALIZATION", response.data);
  }
};
const mutations = {
  SET_SPECIALIZATION: (state, data) => (state.specializations = data)
};

export default {
  state,
  getters,
  actions,
  mutations
};
