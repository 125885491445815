import axios from "@/axios";
const state = {
  offersSubscriptions: {}
};

const getters = {
  allOffersSubscriptions: state => state.offersSubscriptions.data,
  getOffersSubscriptionsCurrentPage: state => state.offersSubscriptions.current_page,
  getOffersSubscriptionsLastPage: state => state.offersSubscriptions.last_page
};
const actions = {
  async fetchOffersSubscriptions({ commit }, info) {
    const response = await axios.get(
      "/admin/offers/subscriptions?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_OFFERS_SUBSCRIPTIONS", response.data);
  },
  

  setOffersSubscriptionsCurrentPage({ commit }, currentPage) {
    commit("SET_OFFERS_SUBSCRIPTIONS_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
 
  SET_OFFERS_SUBSCRIPTIONS: (state, data) => (state.offersSubscriptions = data),
  SET_OFFERS_SUBSCRIPTIONS_CURRENT_PAGE: (state, data) => (state.offersSubscriptions.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations
};
