<template>
  <div class="userinfo ">
    <v-container>
      <div class="title mb-5" style="font-family: cairo!important;">
        الحساب الشخصي
      </div>
      <ChangePassword />

      <v-row>
        <v-col cols="12">
          <v-list two-line class="text-right" color="transparent">
            <v-subheader>معلومات الحساب</v-subheader>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>الاسم الكامل</v-list-item-title>
                <v-list-item-subtitle>{{
                  userInfo.fullname
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>اسم المستخدم</v-list-item-title>
                <v-list-item-subtitle>{{
                  userInfo.username
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>تاريخ الانشاء</v-list-item-title>
                <v-list-item-subtitle dir="ltr">{{
                  userInfo.created_at.substring(0, 10)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChangePassword from "../../components/models/global/ChangePassword";
export default {
  name: "userInfo",
  components: { ChangePassword },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {},
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
};
</script>

<style scoped lang="scss">
$accentColor: #2196f3;
$textColor: #fff;
$fcolor: rgb(100, 20, 80);
$bcolor: black;
$bgcolor: white;
$bgimage_path: "../assets/imgs/leftbackground.png";
$fontname: "JF Flat";

.userinfo {
  border: none;
  border-radius: 20px;
  vertical-align: middle;
  text-align: right;
  direction: rtl;
  font-family: $fontname;
}

.texttitles {
  font-size: large;
}

h2 {
  text-align: center;
}
</style>
