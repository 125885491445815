import axios from "@/axios";
const state = {
  advices:{}
};

const getters = {
  allAdvices: state => state.advices.data,
  getMedicalAdvice: (state) => (id) => {
    var advice = state.advices.data.find((advice) => advice.id === id);
    return advice;
  }, 
  getAdvicesCurrentPage: state => state.advices.current_page,
  getAdvicesLastPage: state => state.advices.last_page
};
const actions = {
  async fetchAdvices({ commit }, info) {
    const response = await axios.get(
      "/admin/medicalAdvices?per_page=10&&page=" +
      info.page+"&&search="+info.keyword
    );
    commit("SET_ADVICES", response.data);
  },
  async updateMedicalAdviceData({ commit }, advice) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/admin/medicalAdvices/" + advice.id, advice);
   
    if (response.data.success == "true")
      commit("UPDATE_MEDICAL_ADVICE", response.data.data);
    return response.data.message;
  },
  async deleteAdvice({ commit }, id) {
    const response = await axios.post("/admin/medicalAdvices/" + id,{"_method":"delete"});
    if (response.data.message == "deleted") {
      commit("DELETE_ADVICE", id);
      return "deleted";
    } else return "failed";
  },
  setAdvicesCurrentPage({ commit }, currentPage) {
    commit("SET_ADVICES_CURRENT_PAGE", currentPage);
  },
  setAdvicesLastPage({ commit }, lastPage) {
    commit("SET_ADVICES_LAST_PAGE", lastPage);
  },
};
const mutations = {
  SET_ADVICES: (state, data) => (state.advices = data),
  SET_ADVICES_CURRENT_PAGE:(state, data) => (state.advices.current_page = data),
  SET_ADVICES_LAST_PAGE:(state, data) => (state.advices.last_page = data),
  DELETE_ADVICE: (state, id) => {
    const i = state.advices.data.map(item => item.id).indexOf(id);
    state.advices.data.splice(i, 1);
  },
  UPDATE_MEDICAL_ADVICE:(state, data) => {
    const i = state.advices.data.map(item => item.id).indexOf(data.id);
    state.advices.data[i].title=data.title;
    state.advices.data[i].body=data.body;
    state.advices.data[i].category_info.name=data.category_info.name;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
