<template>
  <v-dialog v-model="clinicDialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="indigo"
        style="letter-spacing: 0"
        class="float-right order-1 order-md-2 white--text ml-2 mb-2 d-inline-block"
      >
        <v-icon left dark> mdi-plus </v-icon>
        عيادة جديدة
      </v-btn>
    </template>
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title>
        <span class="headline" style="font-family: 'cairo' !important"
          >معلومات العيادة</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="clinic_form" v-model="clinic_valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="4">
                <v-combobox
                  @keyup="searchForCenters"
                  :search-input.sync="centerNameForSearch"
                  label="اسم المركز الصحي"
                  outlined
                  small-chips
                  :rules="requiredRule"
                  v-model="centerDataFromSearch"
                  :items="getCentersList"
                  :item-text="text"
                  clearable
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Clinic.centerAddress"
                  label="عنوان المركز"
                  outlined
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="centerMobile"
                  label="رقم المركز"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Clinic.clinic_name"
                  label="اسم العيادة"
                  outlined
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="Clinic.city"
                  :items="governorates"
                  item-text="val"
                  item-value="id"
                  label="المحافظة"
                  placeholder="اختر المحافظة"
                  required
                  outlined
                  :rules="requiredRule"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Clinic.clinic_address"
                  label="العنوان"
                  outlined
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-checkbox
                  v-model="Clinic.is_gov_clinic"
                  label="هل هي عيادة شعبية؟"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="Clinic.mobile_line1"
                  label="رقم الهاتف 1"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="Clinic.mobile_line2"
                  label="رقم الهاتف 2"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="chosenDay"
                  :items="days"
                  item-text="val"
                  item-value="val"
                  label="اليوم"
                  placeholder="اختر وقت العمل"
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-dialog
                  ref="fromDialog"
                  v-model="fromDialog"
                  :return-value.sync="chosenTimeFrom"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chosenTimeFrom"
                      label="من توقيت"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="fromDialog"
                    v-model="chosenTimeFrom"
                    full-width
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.fromDialog.save(chosenTimeFrom)"
                    >
                      حفظ
                    </v-btn>
                    <v-btn text color="red" @click="fromDialog = false">
                      الغاء
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="3">
                <v-dialog
                  ref="toDialog"
                  v-model="toDialog"
                  :return-value.sync="chosenTimeTo"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="chosenTimeTo"
                      label="الى توقيت"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="toDialog"
                    v-model="chosenTimeTo"
                    full-width
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.toDialog.save(chosenTimeTo)"
                    >
                      حفظ
                    </v-btn>
                    <v-btn text color="red" @click="toDialog = false">
                      الغاء
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="2" class="mt-3 text-center">
                <v-btn
                  @click="addWorkSchedule"
                  fab
                  small
                  color="green white--text"
                >
                  <v-icon dark> mdi-plus </v-icon></v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-list
                  style="max-height: 250px"
                  class="overflow-y-auto"
                  two-line
                  subheader
                >
                  <v-subheader>التوقيتات</v-subheader>

                  <v-list-item
                    v-for="(schedule, index) in Clinic.work_time"
                    :key="schedule.index"
                  >
                    <v-list-item-icon>
                      <v-icon
                        @click="removeWorkScheduleFromList(index)"
                        color="red"
                        style="cursor: pointer"
                        >mdi-delete</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ schedule.day }} </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ schedule.from }} | {{ schedule.to }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-alert dense text type="success" v-if="successAlrt">
              عملية الاضافة <strong>تمت بنجاح</strong>
            </v-alert>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              فشل في عملية الاضافة الرجاء اعادة المحاولة
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="indigo darken-1 white--text"
          :loading="newClinicLoading"
          :disabled="newClinicLoading"
          @click="addClinic"
        >
          حفظ
        </v-btn>
        <v-btn color="black darken-1" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      الرجاء اختيار اليوم والتوقيت

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewClinic",

  data() {
    return {
      centerMobile: "",
      centerDataFromSearch: null,
      centerNameForSearch: "",
      snackbar: false,
      chosenDay: "",
      chosenTimeFrom: null,
      chosenTimeTo: null,
      fromDialog: false,
      toDialog: false,
      date: new Date().toISOString().substr(0, 10),
      clinicDialog: false,
      clinic_valid: false,
      newClinic: new FormData(),
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
      successAlrt: false,
      errorAlrt: false,
      newClinicLoading: false,

      Clinic: {
        centerId: 0,
        centerName: "",
        centerAddress: "",
        centerMobile: "",
        clinic_name: "",
        clinic_address: "",
        mobile_line1: "",
        mobile_line2: "",
        work_time: [],
        city: 0,
        is_gov_clinic: 0,
      },
      days: [
        { id: 1, val: "الجمعة" },
        { id: 2, val: "السبت" },
        { id: 3, val: "الاحد" },
        { id: 4, val: "الاثنين" },
        { id: 5, val: "الثلاثاء" },
        { id: 6, val: "الاربعاء" },
        { id: 7, val: "الخميس" },
      ],
      governorates: [
        { id: 1, val: "بغداد-الكرخ" },
        { id: 2, val: "بغداد-الرصافة" },
        { id: 3, val: "البصرة" },
        { id: 4, val: "نينوى" },
        { id: 5, val: "ذي قار" },
        { id: 6, val: "ميسان" },
        { id: 7, val: "المثنى" },
        { id: 8, val: "واسط" },
        { id: 9, val: "الديوانية" },
        { id: 10, val: "بابل" },
        { id: 11, val: "كربلاء المقدسة" },
        { id: 12, val: "النجف الاشرف" },
        { id: 13, val: "الانبار" },
        { id: 14, val: "صلاح الدين" },
        { id: 15, val: "ديالى" },
        { id: 16, val: "كركوك" },
        { id: 17, val: "اربيل" },
        { id: 18, val: "السليمانية" },
        { id: 19, val: "دهوك" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCentersList"]),
  },
  watch: {
    centerDataFromSearch(newVal) {
      if (newVal != null && typeof newVal === "object") {
        console.log(newVal);
        this.Clinic.centerId = newVal.id;
        this.Clinic.centerName = newVal.name;
        this.Clinic.centerAddress = newVal.address;
        this.centerMobile = newVal.mobile.startsWith("964")
          ? newVal.mobile.substring(3, newVal.mobile.length)
          : newVal.mobile;

        /*  this.$refs.patientComment.focus(); */
      } else {
        this.Clinic.centerId = 0;
        this.Clinic.centerName = newVal;
        this.Clinic.centerAddress = "";
        this.centerMobile = "";
        this.$refs.clinic_form.resetValidation();
      }
    },
  },
  methods: {
    ...mapActions(["addNewClinic", "fetchCentersList"]),
    text: (item) => item.name + "-" + item.address,
    closeDialog() {
      this.clinicDialog = false;
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    searchForCenters() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.centerNameForSearch != "")
          this.fetchCentersList(this.centerNameForSearch);
      }, 1000);
    },
    addWorkSchedule() {
      if (!this.chosenDay || !this.chosenTimeFrom || !this.chosenTimeTo) {
        this.snackbar = true;
        return;
      }
      var schedule = {
        day: this.chosenDay,
        from: this.chosenTimeFrom,
        to: this.chosenTimeTo,
      };
      this.Clinic.work_time.push(schedule);
    },
    removeWorkScheduleFromList(i) {
      this.Clinic.work_time.splice(i, 1);
    },

    addClinic() {
      if (!this.$refs.clinic_form.validate()) return;
      this.newClinicLoading = true;
      this.newClinic = new FormData();

      this.newClinic.append("centerId", this.Clinic.centerId);
      this.newClinic.append("centerName", this.Clinic.centerName);
      this.newClinic.append("centerAddress", this.Clinic.centerAddress);
      this.newClinic.append("centerMobile", this.centerMobile);
      this.newClinic.append("clinic_name", this.Clinic.clinic_name);
      this.newClinic.append("clinic_address", this.Clinic.clinic_address);
      this.newClinic.append("mobile_line1", this.Clinic.mobile_line1);
      this.newClinic.append("mobile_line2", this.Clinic.mobile_line2);
      this.newClinic.append("work_time", JSON.stringify(this.Clinic.work_time));

      this.newClinic.append(
        "is_gov_clinic",
        this.Clinic.is_gov_clinic == true ? 1 : 0
      );

      this.newClinic.append("city", this.Clinic.city);
      this.addNewClinic(this.newClinic)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.newClinicLoading = false;
          //reset
          this.clinic_valid = false;
          this.Clinic.clinic_name = "";
          this.Clinic.clinic_address = "";
          this.Clinic.mobile_line1 = "";
          this.Clinic.mobile_line2 = "";
          this.Clinic.work_time = [];
          this.Clinic.city = null;
          this.Clinic.is_gov_clinic = 0;
          this.$refs.clinic_form.reset();

          setTimeout(() => {
            this.successAlrt = false;
          }, 3500);
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
          this.errorAlrt = true;
          this.newClinicLoading = false;
        });
    },
  },
};
</script>
