<template>
  <div>
    <v-app-bar color="indigo darken-2" dark dir="rtl" elevation="1">
      <v-toolbar-title>
        <v-btn text to="main"
          >طبيب تايم | <strong class="orange--text">Admin </strong
          ><v-icon class="orange--text" medium right>mdi-star</v-icon>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu left bottom>
        <v-list>
          <v-list-item-group>
            <v-list-item
              v-for="(baroption, i) in baroptions"
              :key="i"
              :to="baroption.route"
            >
              <v-list-item-content>
                <v-list-item-title>{{ baroption.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logoutFunc">
              <v-list-item-content>
                <v-list-item-title>تسجيل الخروج</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          <span>{{ username }} </span>
        </template>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppBar",
  data: function() {
    return {
      username: "",
      baroptions: [
        {
          id: 1,
          name: "ادارة الحساب",
          route: "info",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["signout"]),
    logoutFunc() {
      if (localStorage.getItem("admin_token"))
        this.signout().then(() => {
          this.$router.push("/");
        });
    },
  },
  computed: {
    ...mapGetters(["authenticated"]),
  },
  created() {
    this.username = this.authenticated.username;
  },
};
</script>
