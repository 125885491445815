<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="offers">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        الاعلانات
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block mt-0 mb-2"
                style="vertical-align: center; margin-top: 0"
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right"
                    @click="searchInOffers()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                @click="showOffersSubscriptionsPage"
                class="float-right float-sm-left mb-2"
                color="red white--text"
                ><v-icon left>mdi-credit-card-settings-outline</v-icon>
                الاشتراكات</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="computedHeaders"
          :items="allOffers"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden !important"
        >
          <template v-slot:item.isHidden="{ item }">
            <v-btn color="red--text white" elevation="0" v-if="item.is_hidden">
              <v-icon left>mdi-eye-off</v-icon>
              مخفي
            </v-btn>
            <v-btn color="primary--text white" elevation="0" v-else>
              <v-icon left class="float-right">mdi-eye</v-icon>
              ظاهر
            </v-btn>
          </template>
          <template v-slot:item.shortenBody="{ item }">
            <span @click="showFullbody(item.body)">
              {{ truncate(item.body, 100) }}</span
            >
          </template>
          <template v-slot:item.editOffer="{ item }">
            <v-btn
              icon
              color="amber"
              class="mx-2"
              @click="updateOffer(item.id)"
            >
              <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.deleteOffer="{ item }">
            <v-btn icon color="red" class="mx-2" @click="deleteOffer(item.id)">
              <v-icon dark>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
    <delete-offer
      :id="offerId"
      :DeleteOfferDialog="DeleteOfferDialog"
      @deleteOfferDialogClose="DeleteOfferDialog = $event"
    />
    <update-offer
      :UpdateOfferDialog="UpdateOfferDialog"
      :offerDetails="offerDetails"
      @updateOfferDialogClose="UpdateOfferDialog = $event"
    />
    <full-text-dialog
      :FullTextDialog="FullTextDialog"
      :text="text"
      @fullTextDialogClose="FullTextDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteOffer from "../../components/models/offer/DeleteOffer";
import UpdateOffer from "../../components/models/offer/UpdateOffer";
import FullTextDialog from "../../components/models/global/FullTextDialog";

export default {
  name: "OffersPage",
  components: { DeleteOffer, FullTextDialog, UpdateOffer },
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchOffers(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchOffers(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allOffers", "getRole", "getOffer"]),
    computedHeaders() {
      if (this.getRole != "superadmin")
        return this.headers.filter((header) => header.text !== "حذف");
      else return this.headers;
    },
    currentGet: {
      get() {
        return this.$store.getters.getOffersCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setOffersCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getOffersLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      offerId: 0,
      FullTextDialog: false,
      UpdateOfferDialog: false,
      offerDetails: {},

      DeleteOfferDialog: false,
      loadingSearch: false,
      text: null,
      headers: [
        { text: "الحالة", value: "isHidden" },
        { text: "اسم الطبيب", value: "user_info.fullname" },
        { text: "اسم المستخدم", value: "user_info.username" },
        { text: "العنوان", value: "title" },
        { text: "نص الاعلان", value: "shortenBody" },
        { text: "السعر", value: "price" },
        { text: "التخفيض", value: "discount_rate" },
        { text: "من تاريخ", value: "from_date" },
        { text: "الى تاريخ", value: "to_date" },
        { text: "تاريخ النشر", value: "created_at" },
        { text: "تعديل", value: "editOffer" },
        { text: "حذف", value: "deleteOffer" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      Info: {
        page: 1,
        keyword: "",
      },
    };
  },

  methods: {
    ...mapActions(["fetchOffers"]),
    showOffersSubscriptionsPage() {
      this.$router.push({ name: "OffersSubscriptionsPage" });
    },
    searchInOffers() {
      this.Info.page = 1;
      this.loadingSearch = true;

      this.disabledPagination = true;
      this.fetchOffers(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },

    deleteOffer(id) {
      this.offerId = id;
      this.DeleteOfferDialog = true;
    },
    updateOffer(id) {
      this.UpdateOfferDialog = true;
      this.offerDetails = this.getOffer(id);
    },
    showFullbody(body) {
      this.text = body;
      this.FullTextDialog = true;
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + " ..." : str;
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  max-height: 82vh;
  overflow: auto;
  .noScroll {
    overflow: hidden !important;
  }
}
</style>
