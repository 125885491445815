<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="notifications">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        الاشعارات
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <!--    <v-col cols="12" sm="8" md="10">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                single-line
                class="d-inline-block"
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right "
                    @click="searchInOffers()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col> -->
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allNotifications"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden !important"
        >
          <template v-slot:item.pushNotification="{ item }">
            <v-btn
              @click="pushNotificationFun(item.id)"
              rounded
              small
              class="amber white--text"
              v-if="item.is_pushed == 0"
              :loading="pushLoading"
              :disabled="pushLoading"
            >
              <v-icon>mdi-publish</v-icon>
            </v-btn>
            <span v-else class="green--text">
              <v-icon right color="green">mdi-check-all</v-icon>
              <small>تم</small>
            </span>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NotificationsPage",
  components: {},
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchNotifications(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchNotifications(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allNotifications"]),

    currentGet: {
      get() {
        return this.$store.getters.getNotifCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setNotifCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getNotifLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      snackbar: false,
      snackbarText: "",
      loadingSearch: false,
      pushLoading: false,
      headers: [
        { text: "الاسم", value: "user_info.fullname" },
        { text: "اسم المستخدم", value: "user_info.username" },
        { text: "العنوان", value: "title" },
        { text: "نص التنبيه", value: "body" },
        { text: "تاريخ النشر", value: "created_at" },
        { text: "نشر الاعلان", value: "pushNotification" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      Info: {
        page: 1,
        keyword: "",
      },
    };
  },

  methods: {
    ...mapActions(["fetchNotifications", "pushNotifications"]),
    pushNotificationFun(id) {
      this.pushLoading = true;
      this.pushNotifications(id)
        .then((res) => {
          this.snackbar = true;
          if (res == "success") {
            this.pushLoading = false;
            this.snackbarText = "العملية تمت بنجاح";
          } else if (res == "noReceivers") {
            this.pushLoading = false;
            this.snackbarText = "عذرا لايوجد مستلمين للاشعار!";
          } else {
            this.pushLoading = false;
            this.snackbarText = "حدث خطاء فشل في العملية";
          }
        })
        .catch(() => {
          this.snackbarText = "حدث خطاء فشل في العملية";
          this.pushLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  max-height: 82vh;
  overflow: auto;
  .noScroll {
    overflow: hidden !important;
  }
}
</style>
