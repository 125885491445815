import axios from "axios";
import store from "../store/index";
import router from "../router";
axios.create();
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

//axios.defaults.baseURL ="https://api.tabibtimes.com/api"
//axios.defaults.baseURL = "https://dtapi.time.health/api";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response.status == 401 || error.response.status == 403) {
      store.dispatch("attempt", null);
      router.push({ name: "Login" }).catch(() => {});
    }

    return Promise.reject(error);
  }
);
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default axios;
