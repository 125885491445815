<template>
  <div class="text-right">
    <v-dialog
      v-if="changeDoctorPasswordDialog"
      :value="changeDoctorPasswordDialog"
      width="500"
    >
      <v-form ref="changePasswordForm" lazy-validation>
        <v-card v-click-outside="handleClickOutSide" class="text-right ">
          <v-card-title class="grey lighten-3">
            <h5 class="mt-4 eng-text" style="font-family:Cairo!important">
              تعديل كلمة السر-{{ fullname }}
            </h5>
          </v-card-title>
          <v-alert dense text type="success" v-if="successAlert">
            عملية التعديل <strong>تمت بنجاح</strong>
          </v-alert>

          <v-alert dense outlined type="error" v-if="errorAlert2">
            فشل في العملية الرجاء اعادة المحاولة
          </v-alert>
          <v-alert dense outlined type="error" v-if="errorAlert3">
            كلمات السر الجديدة غير متطابقة
          </v-alert>

          <v-card-text class="mt-3">
            معلومات كلمة السر الجديدة
          </v-card-text>

          <v-container>
            <h5>كلمة السر الجديدة</h5>
            <v-text-field
              placeholder="كلمة السر الجديدة"
              outlined
              dense
              :rules="requiredRule"
              v-model="Info.newPassword"
              class="mt-2"
              type="password"
            ></v-text-field>
            <h5>تاكيد كلمة السر</h5>
            <v-text-field
              placeholder="تاكيد كلمة السر الجديدة"
              outlined
              dense
              :rules="requiredRule"
              v-model="Info.confirmPassword"
              class="mt-2"
              type="password"
            ></v-text-field>
          </v-container>

          <v-card-actions class="py-4">
            <v-btn color="primary" @click="changeAccountPassword">
              تعديل كلمة السر
            </v-btn>
            <v-btn color="primary" text @click="closeDialog">
              اغلاق
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["changeDoctorPasswordDialog", "uid", "fullname"],
  data() {
    return {
      dialog: false,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      Info: {
        id: 0,
        newPassword: "",
        confirmPassword: "",
      },
      successAlert: false,
      errorAlert2: false,
      errorAlert3: false,
    };
  },
  methods: {
    ...mapActions(["changePasswordByAdmin"]),
    closeDialog() {
      this.$emit("changeDoctorPasswordDialogClosed", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },

    changeAccountPassword() {
      if (!this.$refs.changePasswordForm.validate()) return;
      if (this.Info.confirmPassword != this.Info.newPassword) {
        this.successAlert = false;
        this.errorAlert2 = false;
        this.errorAlert3 = true;
      } else {
        this.Info.id = this.uid;

        this.changePasswordByAdmin(this.Info)
          .then((msg) => {
            if (msg == "updated") {
              this.successAlert = true;
              this.errorAlert2 = false;
              this.errorAlert3 = false;
              setTimeout(() => {
                this.alertText = null;
                this.Info.confirmPassword = "";
                this.Info.confirmPassword = "";
                this.$refs.changePasswordForm.reset();
                this.successAlert = false;
                this.closeDialog();
              }, 3000);
            }
          })
          .catch((error) => {
            if (
              error.response.data.errors.password[0] ==
              "The password is incorrect."
            ) {
              this.successAlert = false;
              this.errorAlert2 = false;
              this.errorAlert3 = false;
            } else {
              this.successAlert = false;
              this.errorAlert2 = true;
              this.errorAlert3 = false;
            }
          });
      }
    },
  },
};
</script>

<style>
v-text-field {
  direction: rtl !important;
}

.eng-text {
  font-family: sans-serif !important;
  color: gray;
}
</style>
