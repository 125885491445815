<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="doctors">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        سجل الاطباء
      </div>
      <v-card class="pb-1">
        <v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block order-2 order-md-1 mt-0 mb-2 ml-0 ml-md-2"
                style="
                  vertical-align: center !important;
                  margin-top: 0 !important;
                "
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    @click="searchInDoctors()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
              <new-doctor />
              <new-clinic-assistant />
              <new-pharmacy-user />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="computedHeaders"
          :items="allDoctors"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
        >
          <template v-slot:item.subscriptionStatus="{ item }">
            <div v-if="item.role != 'clinic_assistant'">
              <v-btn
                rounded
                elevation="0"
                class="diamond-color"
                v-if="item.subscriptionStatus == 'diamond'"
                ><v-icon>mdi-check-decagram</v-icon> ماسي</v-btn
              >

              <v-btn
                rounded
                elevation="0"
                class="platinum-color"
                v-else-if="item.subscriptionStatus == 'platinum'"
                ><v-icon>mdi-check-decagram</v-icon> بلاتيني</v-btn
              >

              <v-btn
                rounded
                elevation="0"
                class="golden-color"
                v-else-if="item.subscriptionStatus == 'golden'"
                ><v-icon>mdi-check-decagram</v-icon> ذهبي</v-btn
              >
              <v-btn
                @click="
                  showActivateSubscriptionDialog(
                    item.id,
                    item.subscriptionStatus,
                    item.fullname
                  )
                "
                rounded
                elevation="0"
                class="expired-color"
                v-else-if="item.subscriptionStatus == 'expired'"
                ><v-icon>mdi-cancel</v-icon> منتهي</v-btn
              >
              <v-btn
                @click="
                  showActivateSubscriptionDialog(
                    item.id,
                    item.subscriptionStatus,
                    item.fullname
                  )
                "
                rounded
                elevation="0"
                class="expired-color"
                v-else
                ><v-icon>mdi-cancel</v-icon> غيرفعال</v-btn
              >
            </div>
            <div v-else>
              <v-btn>لايوجد</v-btn>
            </div>
          </template>
          <template v-slot:item.subExpiryDate="{ item }">
            <span style="white-space: nowrap" v-if="item.subscription">{{
              item.subscription.subscription_expiry_date.substring(0, 10)
            }}</span>
            <span v-else>لايوجد</span>
          </template>
          <template v-slot:item.profile_picture="{ item }">
            <v-avatar size="36">
              <img :src="item.profile_picture" />
            </v-avatar>
          </template>
          <template v-slot:item.formatFees="{ item }">
            <span v-if="item.doctor">{{ item.doctor.fees }}</span>
            <span v-else>لايوجد</span>
          </template>
          <template v-slot:item.deleteDoctor="{ item }">
            <v-btn
              @click="deleteDoctor(item.id)"
              rounded
              small
              class="red white--text"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.changePassword="{ item }">
            <v-btn
              @click="showChangePassword(item.id, item.fullname)"
              rounded
              small
              class="orange white--text"
            >
              <v-icon color="white">mdi-lock-open-plus</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.editDoctor="{ item }">
            <v-btn
              @click="editDoctor(item.id)"
              rounded
              small
              class="amber white--text"
            >
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
        <edit-doctor
          :editDoctorDialog="editDoctorDialog"
          :id="editDoctorId"
          @editDoctorDialogClose="editDoctorDialogClosed($event)"
        />
        <delete-doctor
          :id="deletedId"
          :deleteDialog="deleteDialog"
          @deleteDialogClosed="deleteDialog = $event"
        />
        <change-doctor-password
          :uid="userId"
          :fullname="doctorFullname"
          :changeDoctorPasswordDialog="changeDoctorPasswordDialog"
          @changeDoctorPasswordDialogClosed="
            changeDoctorPasswordDialog = $event
          "
        />
        <activate-doctor-subscription
          :currentSubscription="currentSubscription"
          :fullname="doctorFullname"
          :userId="userId"
          :ActivateDoctorSubscriptionDialog="ActivateDoctorSubscriptionDialog"
          @ActivateDoctorSubscriptionDialogClosed="
            ActivateDoctorSubscriptionDialog = $event
          "
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import NewDoctor from "../../components/models/doctor/NewDoctor";
import NewClinicAssistant from "../../components/models/clinic/NewClinicAssistant";
import NewPharmacyUser from "../../components/models/pharmacy/NewPharmacyUser";
import DeleteDoctor from "../../components/models/doctor/DeleteDoctor";
import EditDoctor from "../../components/models/doctor/EditDoctor";
import changeDoctorPassword from "../../components/models/doctor/ChangeDoctorPassword";
import activateDoctorSubscription from "../../components/models/doctor/ActivateDoctorSubscription.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "DoctorsPage",
  components: {
    NewDoctor,
    DeleteDoctor,
    EditDoctor,
    changeDoctorPassword,
    activateDoctorSubscription,
    NewClinicAssistant,
    NewPharmacyUser
  },
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchDoctors(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchDoctors(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allDoctors", "getRole"]),
    computedHeaders() {
      if (this.getRole != "superadmin")
        return this.headers.filter((header) => header.text !== "حذف");
      else return this.headers;
    },
    currentGet: {
      get() {
        return this.$store.getters.getDoctorCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setDoctorCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getDoctorLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      deletedId: 0,
      userId: 0,
      changeDoctorPasswordDialog: false,
      ActivateDoctorSubscriptionDialog: false,
      currentSubscription: null,
      doctorFullname: "",
      deleteDialog: false,
      loadingSearch: false,
      Info: {
        page: 1,
        keyword: "",
      },
      headers: [
        { text: "الاشتراك", value: "subscriptionStatus" },
        { text: "تاريخ النفاذ", value: "subExpiryDate" },
        { text: "اسم الطبيب", value: "fullname" },
        { text: "اسم المستخدم", value: "username" },
        { text: "العيادة", value: "doctor.clinic.clinic_name" },
        { text: "رقم الهاتف", value: "mobile" },
        { text: "الاجور", value: "formatFees" },
        { text: "العنوان", value: "address" },
        { text: "الصورة الشخصية", value: "profile_picture" },
        { text: "نوع الحساب", value: "role" },
        { text: "كلمة السر", value: "changePassword" },
        { text: "تعديل", value: "editDoctor" },
        { text: "حذف", value: "deleteDoctor" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      editDoctorId: 0,
      editDoctorDialog: false,
    };
  },

  methods: {
    ...mapActions(["fetchDoctors"]),
    searchInDoctors() {
      this.Info.page = 1;
      this.loadingSearch = true;

      this.disabledPagination = true;
      this.fetchDoctors(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
    showActivateSubscriptionDialog(userId, subscriptionStatus, fullname) {
      this.userId = userId;
      this.doctorFullname = fullname;
      this.currentSubscription = subscriptionStatus;
      this.ActivateDoctorSubscriptionDialog = true;
    },
    deleteDoctor(id) {
      this.deleteDialog = true;
      this.deletedId = id;
    },
    showChangePassword(id, fullname) {
      this.doctorFullname = fullname;
      this.userId = id;
      this.changeDoctorPasswordDialog = true;
    },
    editDoctor(userId) {
      this.editDoctorId = userId;
      this.editDoctorDialog = true;
    },
    editDoctorDialogClosed(state) {
      this.editDoctorDialog = state;
      this.editDoctorId = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.doctors {
  max-height: 82vh;
  overflow: auto;
  .diamond-color {
    color: #caf0f8;
    background-color: #4a4a4a;
  }
  .golden-color {
    color: #ffd500;
    background-color: #4a4a4a;
  }
  .platinum-color {
    color: #d3d6d8;
    background-color: #4a4a4a;
  }
  .expired-color {
    color: #fff;
    background-color: #d90429;
  }
}
</style>
