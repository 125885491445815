import axios from "@/axios";
const state = {
  clinics: {},
  clinicList: [],
  centers: [],
};

const getters = {
  allClinics: (state) => state.clinics.data,
  getClinic: (state) => (id) => {
    var clinic = state.clinics.data.find((clinic) => clinic.id === id);
    return clinic;
  },
  getCentersList: (state) => state.centers,
  clinicList: (state) => state.clinicList,
  getClinicCurrentPage: (state) => state.clinics.current_page,
  getClinicLastPage: (state) => state.clinics.last_page,
};
const actions = {
  async fetchCentersList({ commit }, search) {
    const response = await axios.get("/admin/centers?search=" + search);
    if (response.status == 200) commit("SET_CENTERS", response.data);
  },
  async fetchClinics({ commit }, info) {
    const response = await axios.get(
      "/admin/clinics?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_CLINICS", response.data);
  },
  async fetchClinicList({ commit }, name) {
    const response = await axios.get("/admin/clinics/" + name);
    commit("SET_CLINIC_LIST", response.data);
  },
  setClinicCurrentPage({ commit }, currentPage) {
    commit("SET_CLINIC_CURRENT_PAGE", currentPage);
  },
  setClinicLastPage({ commit }, lastPage) {
    commit("SET_CLINIC_LAST_PAGE", lastPage);
  },

  async addNewClinic({ commit }, clinic) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    var newClinic = await axios.post("/admin/clinics", clinic);
    if (newClinic.status === 201) commit("ADD_CLINIC", newClinic.data.data);
  },

  async updateClinicData({ commit }, clinic) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/admin/clinics/" + clinic.id, clinic);

    if (response.data.success == "true")
      commit("UPDATE_CLINIC", response.data.data);
    return response.data.message;
  },
  async deleteClinic({ commit }, id) {
    const response = await axios.post("/admin/clinics/" + id, {
      _method: "delete",
    });
    if (response.data.message == "deleted") {
      commit("DELETE_CLINIC", id);
      return "deleted";
    } else if (response.data.message == "hasDoctors") return "hasDoctors";
    else return "failed";
  },
};
const mutations = {
  SET_CENTERS: (state, data) => (state.centers = data),
  SET_CLINICS: (state, data) => (state.clinics = data),
  SET_CLINIC_LIST: (state, data) => (state.clinicList = data),
  SET_CLINIC_CURRENT_PAGE: (state, data) => (state.clinics.current_page = data),
  SET_CLINIC_LAST_PAGE: (state, data) => (state.clinics.last_page = data),
  ADD_CLINIC: (state, data) => {
    state.clinics.data.unshift(data);
  },
  UPDATE_CLINIC: (state, data) => {
    const i = state.clinics.data.map((item) => item.id).indexOf(data.id);
    state.clinics.data[i].clinic_address = data.clinic_address;
    state.clinics.data[i].clinic_name = data.clinic_name;
    state.clinics.data[i].mobile_line1 = data.mobile_line1;
    state.clinics.data[i].mobile_line2 = data.mobile_line2;
    state.clinics.data[i].city = data.city;
    state.clinics.data[i].work_time = data.work_time;
    state.clinics.data[i].clinic_location_lat_lng =
      data.clinic_location_lat_lng;
  },
  DELETE_CLINIC: (state, id) => {
    const i = state.clinics.data.map((item) => item.id).indexOf(id);
    state.clinics.data.splice(i, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
