<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="patients">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        المراجعين
      </div>
      <!-- counters start -->
      <v-list
        elevation="1"
        rounded
        style="max-height: 80px"
        class="overflow-y-auto mb-1"
      >
        <template>
          <div class="px-2">
            <v-chip
              small
              :color="resCount.color"
              class="ml-1 mb-1"
              v-for="resCount in filteredReservationsCounters"
              :key="resCount.countName"
            >
              <v-icon color="indigo" small left>{{ resCount.icon }}</v-icon>
              {{ resCount.title }}: {{ getCounters[resCount.countName] }}
            </v-chip>
            <!-- by gov -->
            <v-chip
              small
              class="ml-1 mb-1"
              color="grey lighten-4"
              :key="i"
              v-for="(govCount, i) in getCounters.patients_governorates"
            >
              {{ govCount.city }}: {{ govCount.total }}
            </v-chip>
          </div>
        </template>
      </v-list>
      <!-- counters end -->
      <v-card class="pb-1">
        <v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                class="d-inline-block"
                hide-details
                solo
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right"
                    @click="searchInPatients()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allPatients"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
        >
          <template v-slot:item.profile_picture="{ item }">
            <v-avatar color="indigo" size="36">
              <img
                v-if="item.profile_picture != 'default'"
                :src="item.profile_picture"
              />
              <v-icon v-else dark> mdi-account-circle </v-icon>
            </v-avatar>
          </template>
          <template v-slot:item.genderRefactor="{ item }">
            <v-chip small v-if="item.gender == 'male'"
              ><v-icon small left>mdi-human-male</v-icon> ذكر</v-chip
            >
            <v-chip small v-if="item.gender == 'female'"
              ><v-icon small left>mdi-human-female</v-icon> انثى</v-chip
            >
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PatientsPage",
  components: {},
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchPatients(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchPatients(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  mounted() {
    this.fetchCounters();
  },
  computed: {
    ...mapGetters(["allPatients", "getCounters"]),

    filteredReservationsCounters() {
      return this.reservationsCounters.filter(
        (counter) => counter.title != "gov"
      );
    },

    currentGet: {
      get() {
        return this.$store.getters.getPatientsCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setPatientsCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getPatientsLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      loadingSearch: false,
      Info: {
        page: 1,
        keyword: "",
      },
      headers: [
        { text: "الاسم", value: "fullname" },
        { text: "الصورة الشخصية", value: "profile_picture" },
        { text: "الجنس", value: "genderRefactor" },
        { text: "رقم الهاتف", value: "mobile" },
        { text: "تاريخ الميلاد", value: "date_of_birth" },
        { text: "المدينة", value: "city" },
        { text: "العنوان", value: "address" },
        { text: "فصيلة الدم", value: "blood_type" },
        { text: "لقاح كورونا", value: "corona_vaccine" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      reservationsCounters: [
        {
          title: "عدد المراجعين",
          countName: "patients",
          color: "dark--text grey lighten-4",
          icon: "mdi-account-multiple",
        },
        {
          title: "المراجعين الذكور",
          countName: "patients_male",
          color: "dark--text grey lighten-4",
          icon: "mdi-human-male",
        },
        {
          title: "المراجعين الاناث",
          countName: "patients_female",
          color: "dark--text grey lighten-4",
          icon: "mdi-human-female",
        },
        {
          title: "مراجعين ملقحين",
          countName: "patients_corona_vaccinated",
          color: "dark--text grey lighten-4",
          icon: "mdi-virus",
        },
        {
          title: "gov",
          countName: "patients_governorates",
          color: "dark--text grey lighten-4",
          icon: "",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["fetchPatients", "fetchCounters"]),
    searchInPatients() {
      this.Info.page = 1;
      this.loadingSearch = true;
      this.disabledPagination = true;
      this.fetchPatients(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.patients {
  max-height: 82vh;
  overflow: auto;
}
</style>
