import axios from "@/axios";
const state = {
  patients: {}
};

const getters = {
  allPatients: state => state.patients.data,

  getPatientsCurrentPage: state => state.patients.current_page,
  getPatientsLastPage: state => state.patients.last_page
};
const actions = {
  async fetchPatients({ commit }, info) {
    const response = await axios.get(
      "/admin/patients?per_page=10&&page=" + info.page+"&&search="+info.keyword
    );
    commit("SET_PATIENTS", response.data);
  },

  setPatientsCurrentPage({ commit }, currentPage) {
    commit("SET_PATIENTS_CURRENT_PAGE", currentPage);
  },
  setPatientsLastPage({ commit }, lastPage) {
    commit("SET_PATIENTS_LAST_PAGE", lastPage);
  }
};
const mutations = {
  SET_PATIENTS: (state, data) => (state.patients = data),
  SET_PATIENTS_CURRENT_PAGE: (state, data) =>
    (state.patients.current_page = data),
  SET_PATIENTS_LAST_PAGE: (state, data) => (state.patients.last_page = data)
};

export default {
  state,
  getters,
  actions,
  mutations
};
