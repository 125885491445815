<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="reservations">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        سجل الزيارات
      </div>
      <!-- counters start -->
      <v-list
        elevation="1"
        rounded
        style="max-height: 80px"
        class="overflow-y-auto mb-1"
      >
        <template>
          <div class="px-2">
            <v-chip small class="ml-1 mb-1 primary--text grey lighten-4">
              عدد الحجوزات الكلي: {{ getCounters.reservations }}
            </v-chip>
            <v-chip
              small
              :color="resCount.color"
              class="ml-1 mb-1"
              v-for="(resCount, i) in reservationsCounters"
              :key="i"
            >
              {{ resCount.title }}: {{ getCounters[resCount.countName] }}
            </v-chip>
          </div>
        </template>
      </v-list>
      <!-- counters end -->
      <v-card class="pb-1">
        <v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                class="d-inline-block"
                hide-details
                solo
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right"
                    @click="searchInPatientReservations()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allPatientReservations"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
        >
          <!-- 	<template v-slot:item.profile_picture="{ item }">
						<v-avatar size="36">
							<img :src="item.profile_picture" />
						</v-avatar>
					</template>
					 -->

          <template v-slot:item.status="{ item }">
            <!-- waiting -->
            <v-chip
              v-if="item.status == 'waiting'"
              class="ma-2"
              color="primary"
              outlined
              pill
              small
            >
              انتظار
              <v-icon right> mdi-alarm-snooze </v-icon>
            </v-chip>

            <!-- closed -->
            <v-chip
              v-if="item.status == 'closed'"
              class="ma-2"
              color="green"
              outlined
              pill
              small
            >
              تم
              <v-icon right> mdi-check </v-icon>
            </v-chip>
            <!-- canceled -->
            <v-chip
              v-if="item.status == 'canceled'"
              class="ma-2"
              color="red"
              outlined
              pill
              small
            >
              ملغى
              <v-icon right> mdi-cancel </v-icon>
            </v-chip>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PatientReservationPage",
  components: {},
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchPatientReservations(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchPatientReservations(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  mounted() {
    this.fetchCounters();
  },
  computed: {
    ...mapGetters(["allPatientReservations", "getCounters"]),

    currentGet: {
      get() {
        return this.$store.getters.getPrCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setPrCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getPrLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      loadingSearch: false,
      headers: [
        { text: "اسم العيادة", value: "clinic.clinic_name" },
        { text: "عنوان العيادة", value: "clinic.clinic_address" },
        { text: "اسم المراجع", value: "patient.fullname" },
        { text: "تاريخ المراجعة", value: "visit_date" },
        { text: "وقت المراجعة", value: "visit_time" },
        { text: "الحالة", value: "status" },
      ],
      Info: {
        page: 1,
        keyword: "",
      },
      itemPerPage: 10,
      selectedPage: 1,
      reservationsCounters: [
        {
          title: "حجوزات اليوم",
          countName: "reservations_today",
          color: "dark--text grey lighten-4",
        },
        {
          title: "حجوزات الشهر",
          countName: "reservations_month",
          color: "dark--text grey lighten-4",
        },
        {
          title: "حجوزات السنة",
          countName: "reservations_year",
          color: "dark--text grey lighten-4",
        },
        {
          title: "حجوزات تامة",
          countName: "reservations_closed",
          color: "green--text grey lighten-4",
        },
        {
          title: "حجوزات قيدالانتظار",
          countName: "reservations_waiting",
          color: "indigo--text grey lighten-4",
        },
        {
          title: "حجوزات ملغاة",
          countName: "reservations_canceled",
          color: "red--text grey lighten-4",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["fetchPatientReservations", "fetchCounters"]),
    searchInPatientReservations() {
      this.Info.page = 1;
      this.loadingSearch = true;

      this.disabledPagination = true;
      this.fetchPatientReservations(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reservations {
  max-height: 82vh;
  overflow: auto;
}
</style>
