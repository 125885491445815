import axios from "@/axios";
const state = {
  patient_reservations: {}
};

const getters = {
  allPatientReservations: state => state.patient_reservations.data,

  getPrCurrentPage: state => state.patient_reservations.current_page,
  getPrLastPage: state => state.patient_reservations.last_page
};
const actions = {
  async fetchPatientReservations({ commit }, info) {
    const response = await axios.get(
      "/admin/reservations?per_page=10&&page=" +info.page+"&&search="+info.keyword
    );
    commit("SET_PATIENT_RESERVATION", response.data);
  },

  setPrCurrentPage({ commit }, currentPage) {
    commit("SET_PR_CURRENT_PAGE", currentPage);
  },
  setPrLastPage({ commit }, lastPage) {
    commit("SET_PR_LAST_PAGE", lastPage);
  }
};
const mutations = {
  SET_PATIENT_RESERVATION: (state, data) => (state.patient_reservations = data),
  SET_PR_CURRENT_PAGE: (state, data) =>
    (state.patient_reservations.current_page = data),
  SET_PR_LAST_PAGE: (state, data) =>
    (state.patient_reservations.last_page = data)
};

export default {
  state,
  getters,
  actions,
  mutations
};
