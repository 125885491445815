import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/global/Login.vue";
import Index from "../views/dashboard/Index.vue";
import MainPage from "../views/dashboard/MainPage.vue";
import ClinicsPage from "../views/dashboard/ClinicsPage.vue";
import DoctorsPage from "../views/dashboard/DoctorsPage.vue";
import PatientReservationsPage from "../views/dashboard/PatientReservationsPage.vue";
import PatientsPage from "../views/dashboard/PatientsPage.vue";
import UserInfo from "../views/global/UserInfo.vue";
import OffersPage from "../views/dashboard/OffersPage.vue";
import NotificationsPage from "../views/dashboard/NotificationsPage.vue";
import MedicalAdvicesPage from "../views/dashboard/MedicalAdvicesPage.vue";
import OffersSubscriptions from "../views/dashboard/OffersSubscriptionsPage.vue";

import store from "../store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },

  {
    path: "/dashboard/",
    name: "Index",
    component: Index,
    redirect: "/dashboard/main",
    meta: { requiresAuth: true },
    children: [
      {
        path: "main",
        name: "MainPage",
        component: MainPage
      },
      {
        path: "clinics",
        name: "ClinicsPage",
        component: ClinicsPage
      },
      
      {
        path: "offers",
        name: "OffersPage",
        component: OffersPage
      },
      
      {
        path: "offersSubscriptions",
        name: "OffersSubscriptionsPage",
        component: OffersSubscriptions
      },
      {
        path: "notifications",
        name: "NotificationsPage",
        component: NotificationsPage
      },
      {
        path: "advices",
        name: "MedicalAdvicesPage",
        component: MedicalAdvicesPage
      },
      {
        path: "doctors",
        name: "DoctorsPage",
        component: DoctorsPage
      },
      {
        path: "reservations",
        name: "PatientReservationsPage",
        component: PatientReservationsPage
      },
      {
        path: "patients",
        name: "PatientsPage",
        component: PatientsPage
      },
      {
        path: "info",
        name: "UserInfo",
        component: UserInfo
      }
    ],
    beforeEnter: (to, from, next) => {
      
      if (to.matched.some(record => record.meta.requiresAuth) &&!store.getters["authenticated"]) {
        return next({
          name: "Login"
        });
      }
      next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
