<template>
  <div class="user-dashboard">
    <AppBar />
    <v-container fluid class="px-1 px-sm-2 px-md-3">
      <v-row>
        <v-col cols="2" class="px-1 p-sm-3">
          <Sidebar />
        </v-col>

        <v-col cols="10" class="pl-3 pr-1 px-sm-1 px-md-2">
          <router-view />
        </v-col>
      </v-row>
      <AppFooter />
    </v-container>
  </div>
</template>

<script>
import AppBar from "../../components/global/AppBar";
import Sidebar from "../../components/global/Sidebar";
import AppFooter from "../../components/global/AppFooter";

export default {
  name: "UserDashboard",
  components: {
    AppBar,
    Sidebar,
    AppFooter,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scopped>
body,
html {
  overflow: hidden;
  height: 100%;
}
.user-dashboard {
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  background-color: #f4f6fa;
  .scroll-y {
    overflow-y: scroll;
    max-height: 75vh;
    padding-bottom: 2rem;
  }
  .scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
    background-color: #cccccc;
  }

  .scroll-y::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
  }

  .scroll-y::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent
    );
  }
}
</style>
