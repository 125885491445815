<template>
  <v-list
    nav
    elevation="1"
    class=" mx-0 mx-md-2 py-3 pb-5  mx-lg-3 mt-8 pa-md-2 px-md-2 pa-2 mx-right overflow-y-auto"
    rounded
    dense
    style="max-height:80vh"
  >
    <v-list-item-group v-model="selectedItem">
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        class="mb-4"
        :to="item.route"
        color="primary"
      >
        <v-list-item-icon style="margin-left:5px;">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-center">
          <v-list-item-title v-text="item.title" class="text-center ">
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logoutFunc">
        <v-list-item-icon style="margin-left:5px;">
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-center">
          <v-list-item-title class="text-center ">
            خروج
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<style lang="scss" scopped>
.list-item-style {
  font-size: 11px !important;
  font-weight: light;
}
</style>
<script>
import { mapActions } from "vuex";
export default {
  name: "AppSideBar",
  methods: {
    ...mapActions(["signout"]),
    logoutFunc() {
      if (localStorage.getItem("admin_token"))
        this.signout().then(() => {
          this.$router.push("/");
        });
    },
  },
  data() {
    return {
      drawer: true,
      selectedItem: 0,
      items: [
        { title: "الرئيسية", icon: "mdi-home", route: "main" },
        {
          title: "العيادات",
          icon: "mdi-hospital-building",
          route: "clinics",
        },
        { title: "الاطباء والسكرتارية", icon: "mdi-doctor", route: "doctors" },
        {
          title: "سجل الزيارات",
          icon: "mdi-list-status",
          route: "reservations",
        },
        {
          title: "الاعلانات",
          icon: "mdi-bullhorn",
          route: "offers",
        },
        {
          title: "الاشعارات",
          icon: "mdi-bell",
          route: "notifications",
        },
        {
          title: "النصائح الطبية",
          icon: "mdi-lightbulb",
          route: "advices",
        },
        { title: "المراجعين", icon: "mdi-account-multiple", route: "patients" },
      ],
      mini: false,
    };
  },
};
</script>
