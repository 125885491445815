<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="clinics">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-5" style="font-family: cairo !important">
        سجل العيادات
      </div>
      <!-- counters start -->
      <v-list
        elevation="1"
        rounded
        style="max-height: 80px"
        class="overflow-y-auto mb-1"
      >
        <template>
          <div class="px-2 my-0">
            <v-chip small class="ml-1 mb-1 primary--text grey lighten-4">
              عدد العيادات الكلي: {{ getCounters.clinics }}
            </v-chip>
            <v-chip
              small
              class="ml-1 mb-1 grey lighten-4"
              :key="i"
              v-for="(govCount, i) in getCounters.clinics_governorates"
            >
              {{ govCount.city }}: {{ govCount.total }}
            </v-chip>
          </div>
        </template>
      </v-list>
      <!-- counters end -->
      <v-card class="pb-1">
        <v-card-title>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block order-2 order-md-1 mt-0 mb-2 ml-0 ml-md-2"
                style="
                  vertical-align: center !important;
                  margin-top: 0 !important;
                "
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    @click="searchInClinics()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
              <new-clinic />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="computedHeaders"
          :items="allClinics"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
        >
          <template v-slot:item.dateRefactor="{ item }">
            <span dir="ltr">{{ item.created_at }}</span>
          </template>
          <template v-slot:item.deleteClinic="{ item }">
            <v-btn
              @click="deleteClinic(item.id)"
              rounded
              small
              class="red white--text"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.editClinic="{ item }">
            <v-btn
              @click="editClinic(item.id)"
              rounded
              small
              class="amber white--text"
            >
              <v-icon color="white">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
          :disabled="disabledPagination"
        ></v-pagination>
        <!-- pagination -->
        <edit-clinic
          :editClinicDialog="editClinicDialog"
          :clinicDetails="clinicData"
          @editClinicDialogClose="editClinicDialogClosed($event)"
        />
        <delete-clinic
          :id="deletedId"
          :deleteDialog="deleteDialog"
          @deleteDialogClosed="deleteDialog = $event"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import NewClinic from "../../components/models/clinic/NewClinic";
import DeleteClinic from "../../components/models/clinic/DeleteClinic";
import EditClinic from "../../components/models/clinic/EditClinic";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ClinicPage",
  components: { NewClinic, DeleteClinic, EditClinic },
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchClinics(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  mounted() {
    this.fetchCounters();
  },
  created() {
    this.disabledPagination = true;
    this.fetchClinics(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allClinics", "getRole", "getCounters", "getClinic"]),
    computedHeaders() {
      if (this.getRole != "superadmin")
        return this.headers.filter((header) => header.text !== "حذف");
      else return this.headers;
    },
    currentGet: {
      get() {
        return this.$store.getters.getClinicCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setClinicCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getClinicLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      deletedId: 0,
      deleteDialog: false,
      editClinicId: 0,
      clinicData: {},
      editClinicDialog: false,
      loadingSearch: false,
      Info: {
        page: 1,
        keyword: "",
      },
      headers: [
        { text: "اسم العيادة", value: "clinic_name" },
        { text: "المدينة", value: "city" },
        { text: "العنوان", value: "clinic_address" },
        { text: "رقم الهاتف", value: "mobile_line1" },
        { text: "تاريخ الاضافة", value: "dateRefactor" },
        { text: "تعديل", value: "editClinic" },
        { text: "حذف", value: "deleteClinic" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
    };
  },

  methods: {
    ...mapActions(["fetchClinics", "fetchCounters"]),
    searchInClinics() {
      this.Info.page = 1;
      this.loadingSearch = true;

      this.disabledPagination = true;
      this.fetchClinics(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
    editClinicDialogClosed(state) {
      this.editClinicDialog = state;
      this.clinicData = {};
    },
    deleteClinic(id) {
      this.deleteDialog = true;
      this.deletedId = id;
    },
    editClinic(id) {
      this.clinicData = this.getClinic(id);
      this.editClinicId = id;
      this.editClinicDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.clinics {
  max-height: 82vh;
  overflow: auto;
}
</style>
