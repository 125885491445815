<template>
  <v-dialog
    :value="ActivateDoctorSubscriptionDialog"
    v-if="ActivateDoctorSubscriptionDialog"
    persistent
    max-width="600px"
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title>
        <span class="headline" style="font-family:'cairo'!important;"
          >تفعيل الاشتراك: <small>{{ fullname }}</small></span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="subscription_form" lazy-validation>
            <v-row>
              <v-col>
                <span
                  >الاشتراك الحالي:
                  <strong>{{ currentSubscriptionArabic }}</strong></span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedPackage"
                  :items="getFinancePackagesList"
                  label="نوع الاشتراك"
                  placeholder="اختر نوع الاشتراك"
                  required
                  :rules="requiredRule"
                  return-object
                  item-value="id"
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.months }} شهر-{{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="requiredRuleNumber"
                  type="number"
                  v-model="discount"
                  label="الخصم بالمئة"
                  outlined
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="requiredRuleNumber"
                  type="number"
                  v-model="tottalPrice"
                  readonly
                  label="مبلغ الاشتراك"
                  outlined
                  append-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-alert dense text type="success" v-if="successAlrt">
              العملية <strong>تمت بنجاح</strong>
            </v-alert>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              فشل في العملية الرجاء اعادة المحاولة
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="green darken-1 white--text"
          :loading="subscriptionLoading"
          :disabled="subscriptionLoading"
          @click="activateSubscriptionFun"
        >
          تفعيل الاشتراك
        </v-btn>
        <v-btn color="black darken-1" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ActivateDoctorSupscription",
  created() {
    this.fetchFinancePackages();
  },
  computed: {
    ...mapGetters(["getFinancePackagesList"]),
    currentSubscriptionArabic() {
      if (this.currentSubscription == "inactive") return "غير مشترك";
      else if (this.currentSubscription == "expired") return "منتهي الصلاحية";
      else if (this.currentSubscription == "golden") return "ذهبي";
      else if (this.currentSubscription == "platinum") return "بلاتيني";
      else if (this.currentSubscription == "diamond") return "ماسي";
      else return "غير معرف";
    },
  },
  watch: {
    selectedPackage(newVal) {
      if (newVal != null)
        this.tottalPrice = newVal.price - (newVal.price * this.discount) / 100;
    },
    discount(newVal) {
      if (this.selectedPackage != null)
        this.tottalPrice =
          this.selectedPackage.price -
          (this.selectedPackage.price * newVal) / 100;
    },
  },
  props: [
    "ActivateDoctorSubscriptionDialog",
    "userId",
    "fullname",
    "currentSubscription",
  ],
  data() {
    return {
      newSubscription: new FormData(),
      requiredRuleNumber: [
        (v) => {
          if (parseInt(v) >= 0) return true;
          return "هذا الحقل مطلوب";
        },
      ],
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
      successAlrt: false,
      errorAlrt: false,
      subscriptionLoading: false,
      selectedPackage: null,
      tottalPrice: 0,
      discount: 0,
    };
  },
  methods: {
    ...mapActions(["activateSubscription", "fetchFinancePackages"]),
    closeDialog() {
      this.$emit("ActivateDoctorSubscriptionDialogClosed", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },

    activateSubscriptionFun() {
      if (!this.$refs.subscription_form.validate()) return;
      this.subscriptionLoading = true;
      this.newSubscription.append("userId", this.userId);
      this.newSubscription.append("discount", this.discount);
      this.newSubscription.append("packageId", this.selectedPackage.id);

      this.activateSubscription(this.newSubscription)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.subscriptionLoading = false;

          this.tottalPrice = 0;
          this.selectedPackage = null;
          this.discount = 0;
          //reset
          this.newSubscription = new FormData();
          this.$refs.subscription_form.reset();

          setTimeout(() => {
            this.closeDialog();
            this.successAlrt = false;
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          this.successAlrt = false;
          this.errorAlrt = true;
          this.subscriptionLoading = false;
        });
    },
  },
};
</script>
