<template>
  <v-dialog :value="editMedicalAdviceDialog" width="500" persistent>
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3 grey lighten-4">
        <h5 class="text-right pr-1">
          تعديل النصيحة الطبية
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية التحديث تمت بنجاح
        </v-alert>
        <v-form ref="edit_advice_form" lazy-validation>
          <v-text-field
            label="عنوان النصيحة"
            :rules="requiredRule"
            v-model="medicalAdviceData.title"
          >
          </v-text-field>
          <v-textarea
            label="نص النصيحة"
            v-model="medicalAdviceData.body"
            :rules="requiredRule"
            rows="2"
          >
          </v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="updateMedicalAdvice"
          large
        >
          تحديث
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["editMedicalAdviceDialog", "medicalAdviceDetails"],

  data() {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      medicalAdviceData: Object.assign({}, this.medicalAdviceDetails),
      Advice: {
        title: null,
        body: null,
        id: 0,
        _method: "PUT",
      },
    };
  },
  watch: {
    medicalAdviceDetails(new_value) {
      this.medicalAdviceData = Object.assign({}, new_value);
    },
  },
  methods: {
    ...mapActions(["updateMedicalAdviceData"]),
    closeDialog() {
      this.$emit("editMedicalAdviceDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },

    updateMedicalAdvice() {
      if (!this.$refs.edit_advice_form.validate()) return;
      this.loadingSpinner = true;
      this.Advice.title = this.medicalAdviceData.title;
      this.Advice.body = this.medicalAdviceData.body;
      this.Advice.id = this.medicalAdviceData.id;

      this.updateMedicalAdviceData(this.Advice)
        .then((msg) => {
          if (msg == "success") {
            this.loadingSpinner = false;
            this.successAlrt = true;
            this.errorAlrt = false;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$refs.edit_advice_form.reset();
              this.$emit("editMedicalAdviceDialogClose", false);
            }, 3000);
          } else {
            this.successAlrt = false;
            this.errorAlrt = true;
            this.loadingSpinner = false;
          }
        })
        .catch(() => {
          this.errorAlrt = true;
          this.successAlrt = false;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
