<template>
  <v-dialog v-model="doctorDialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="green"
        style="letter-spacing: 0"
        class="float-right order-1 order-md-2 white--text ml-2 mb-2 d-inline-block"
      >
        <v-icon left dark> mdi-plus </v-icon>
        طبيب جديد
      </v-btn>
    </template>
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title>
        <span class="headline" style="font-family: 'cairo' !important"
          >معلومات الطبيب</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="doctor_form" v-model="doctor_valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedPackage"
                  :items="getFinancePackagesList"
                  label="نوع الاشتراك"
                  placeholder="اختر نوع الاشتراك"
                  required
                  :rules="requiredRule"
                  return-object
                  item-value="id"
                  outlined
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.months }} شهر-{{ data.item.name }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="requiredRuleNumber"
                  type="number"
                  v-model="discount"
                  label="الخصم بالمئة"
                  outlined
                  append-icon="mdi-percent"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :rules="requiredRuleNumber"
                  type="number"
                  v-model="tottalPrice"
                  readonly
                  label="مبلغ الاشتراك"
                  outlined
                  append-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  @keyup="searchForClinic"
                  v-model="Doctor.clinic_id"
                  :search-input.sync="searchClinic"
                  :items="clinicList"
                  item-value="id"
                  label="اختر العيادة"
                  placeholder="بحث عن اسم العيادة"
                  required
                  :rules="requiredRule"
                  outlined
                  :item-text="clinicFormatText"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Doctor.fullname"
                  label="الاسم الكامل"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="Doctor.username"
                  label="اسم المستخدم"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="password"
                  v-model="Doctor.password"
                  label="كلمة السر"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="Doctor.gender"
                  :items="genders"
                  item-text="val"
                  item-value="id"
                  label="الجنس"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col v-if="Doctor.role == 3" cols="12" md="4">
                <v-select
                  v-model="Doctor.specializations_id"
                  item-text="name"
                  item-value="id"
                  :items="getSpecializations"
                  label="اختر التخصص"
                  multiple
                  chips
                  hint="يمكنك اختيار اكثر من تخصص"
                  persistent-hint
                  outlined
                >
                </v-select>
              </v-col>
              <v-col v-if="Doctor.role == 3" cols="12" md="3">
                <v-text-field
                  :rules="requiredRule"
                  type="number"
                  v-model="Doctor.fees"
                  label="الكشفية بالدينار"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  v-model="Doctor.bio"
                  label="وصف عن الطبيب"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  ref="dobMenu"
                  v-model="dobMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="Doctor.date_of_birth"
                      label="تاريخ الميلاد"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="Doctor.date_of_birth"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="1930-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="Doctor.mobile"
                  label="رقم الهاتف"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="text"
                  v-model="Doctor.address"
                  label="العنوان"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-file-input
                  v-model="Doctor.profile_picture"
                  :rules="(rules, requiredRule)"
                  accept="image/png, image/jpeg,image/jpg, image/svg"
                  placeholder="الصورة الشخصية"
                  truncate-length="15"
                  append-icon="mdi-paperclip"
                  prepend-icon=""
                  outlined
                ></v-file-input>
              </v-col>
            </v-row>
            <v-divider class="py-2"></v-divider>
            <v-row v-if="Doctor.role == 3">
              <v-col cols="12" md="4">
                <v-text-field
                  type="text"
                  v-model="chosenQualName"
                  label="اسم الشهادة الجامعية"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  type="text"
                  v-model="chosenQualCollegeName"
                  label="اسم الجامعة"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2" md="1" class="mt-3">
                <v-btn
                  @click="addQualification"
                  fab
                  small
                  color="green white--text"
                >
                  <v-icon dark> mdi-plus </v-icon></v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-list
                  style="max-height: 250px"
                  class="overflow-y-auto"
                  two-line
                  subheader
                >
                  <v-subheader>الشهادات</v-subheader>

                  <v-list-item
                    v-for="qualification in Doctor.qualifications"
                    :key="qualification.index"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ qualification.name }} |
                        {{ qualification.college }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-alert dense text type="success" v-if="successAlrt">
              عملية الاضافة <strong>تمت بنجاح</strong>
            </v-alert>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              فشل في عملية الاضافة الرجاء اعادة المحاولة
            </v-alert>
            <v-alert dense outlined type="warning" v-if="duplicateAlrt">
              اسم المستخدم محجوز يرجى اختيار اسم اخر
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="green darken-1 white--text"
          :loading="newDoctorLoading"
          :disabled="newDoctorLoading"
          @click="addDoctor"
        >
          حفظ
        </v-btn>
        <v-btn color="black darken-1" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NewDoctor",
  created() {
    this.fetchSpecializations();
    this.fetchFinancePackages();
  },
  computed: {
    ...mapGetters([
      "clinicList",
      "getSpecializations",
      "getFinancePackagesList",
    ]),
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedPackage(newVal) {
      if (newVal != null)
        this.tottalPrice = newVal.price - (newVal.price * this.discount) / 100;
    },
    discount(newVal) {
      if (this.selectedPackage != null)
        this.tottalPrice =
          this.selectedPackage.price -
          (this.selectedPackage.price * newVal) / 100;
    },
  },
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "حجم الصورة يجب ان يكون اقل من 2 ميكا بايت",
      ],
      genders: [
        { id: 1, val: "ذكر" },
        { id: 2, val: "انثى" },
      ],

      /*  roles: [
        { id: 3, val: "طبيب" },
          { id: 4, val: "مساعد طبيب" },
      ], */
      searchClinic: "",
      dobMenu: false,
      activePicker: null,

      chosenQualName: "",
      chosenQualCollegeName: "",
      date: new Date().toISOString().substr(0, 10),
      doctorDialog: false,
      doctor_valid: false,
      newDoctor: new FormData(),
      requiredRuleNumber: [
        (v) => {
          if (parseInt(v) >= 0) return true;
          return "هذا الحقل مطلوب";
        },
      ],
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
      successAlrt: false,
      errorAlrt: false,
      duplicateAlrt: false,
      newDoctorLoading: false,

      selectedPackage: null,
      tottalPrice: 0,
      discount: 0,

      Doctor: {
        clinic_id: 0,
        fullname: "",
        username: "",
        password: "",
        specializations_id: [],
        mobile: "",
        address: "",
        fees: null,
        role: 3,
        profile_picture: null,
        gender: 0,
        date_of_birth: "",
        qualifications: [],
        packageId: 0,
        discount: 0,
        bio: null,
      },
      days: [
        { id: 1, val: "الجمعة" },
        { id: 2, val: "السبت" },
        { id: 3, val: "الاحد" },
        { id: 4, val: "الاثنين" },
        { id: 5, val: "الثلاثاء" },
        { id: 6, val: "الاربعاء" },
        { id: 7, val: "الخميس" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "addNewDoctor",
      "fetchClinicList",
      "fetchSpecializations",
      "fetchFinancePackages",
    ]),
    clinicFormatText: (item) => item.clinic_name + "-" + item.clinic_address,
    closeDialog() {
      this.newDoctor = new FormData();
      this.doctorDialog = false;
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    searchForClinic() {
      setTimeout(() => {
        if (this.searchClinic != "") this.fetchClinicList(this.searchClinic);
      }, 2000);
    },
    save(date) {
      this.$refs.dobMenu.save(date);
    },
    addQualification() {
      var qualification = {
        name: this.chosenQualName,
        college: this.chosenQualCollegeName,
      };
      console.log(qualification);
      this.Doctor.qualifications.push(qualification);
    },
    addDoctor() {
      if (!this.$refs.doctor_form.validate()) return;
      this.newDoctorLoading = true;
      this.duplicateAlrt = false;
      this.newDoctor = new FormData();
      this.newDoctor.append("fullname", this.Doctor.fullname);
      this.newDoctor.append("username", this.Doctor.username);
      this.newDoctor.append("password", this.Doctor.password);
      this.newDoctor.append("mobile", this.Doctor.mobile);
      this.newDoctor.append("fees", this.Doctor.fees);
      this.newDoctor.append("address", this.Doctor.address);
      this.newDoctor.append("role", this.Doctor.role);
      this.newDoctor.append("clinic_id", this.Doctor.clinic_id);
      this.newDoctor.append("profile_picture", this.Doctor.profile_picture);
      if (this.Doctor.role == 3) {
        this.newDoctor.append("gender", this.Doctor.gender);
        this.newDoctor.append("date_of_birth", this.Doctor.date_of_birth);
        this.newDoctor.append(
          "qualifications",
          JSON.stringify(this.Doctor.qualifications)
        );
        this.newDoctor.append(
          "specializations_id",
          JSON.stringify(this.Doctor.specializations_id)
        );
      }
      this.newDoctor.append("bio", this.Doctor.bio);
      this.newDoctor.append("discount", this.discount);
      this.newDoctor.append("packageId", this.selectedPackage.id);

      this.addNewDoctor(this.newDoctor)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.newDoctorLoading = false;

          this.tottalPrice = 0;
          this.selectedPackage = null;
          this.discount = 0;
          //reset
          this.Doctor.fullname = "";
          this.Doctor.username = "";
          this.Doctor.password = "";
          this.Doctor.mobile = "";
          this.Doctor.fees = 0;
          this.Doctor.address = "";
          this.Doctor.role = 3;
          this.Doctor.profile_picture = null;
          this.Doctor.gender = 0;
          this.Doctor.date_of_birth = "";
          this.Doctor.qualifications = [];
          this.Doctor.packageId = 0;
          this.Doctor.discount = 0;
          this.Doctor.bio = null;
          this.$refs.doctor_form.reset();

          setTimeout(() => {
            this.doctor_valid = false;
            this.successAlrt = false;
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors) {
            if (err.response.data.errors.username[0]) this.duplicateAlrt = true;
          } else this.errorAlrt = true;
          this.newDoctorLoading = false;
        });
    },
  },
};
</script>
