<template>
  <div class="text-right">
     <v-dialog
      v-if="FullTextDialog"
      :value="FullTextDialog"
      width="500"
    >
      <v-card v-click-outside="closeDialog">
        <v-card-text class="pa-3">
       {{text}}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["FullTextDialog","text"],

  data() {
    return {
      
    };
  },
  methods: {
    closeDialog() {
      this.$emit("fullTextDialogClose", false);
    },
    
  }
};
</script>

