import axios from "@/axios";
const state = {
  offers:{}
};

const getters = {
  allOffers: state => state.offers.data,
  getOffer: (state) => (id) => {
    var offer = state.offers.data.find((offer) => offer.id === id);
    return offer;
  }, 
  getOffersCurrentPage: state => state.offers.current_page,
  getOffersLastPage: state => state.offers.last_page
};
const actions = {
  async fetchOffers({ commit }, info) {
    const response = await axios.get(
      "/admin/offers?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_OFFERS", response.data);
  },
  async addOffer(_, offer) {
    const response = await axios.post("/admin/offers", {
      title: offer.title,
      body: offer.body,
      price: offer.price,
      discount_rate: offer.discount,
      from_date: offer.fromDate,
      to_date: offer.toDate
    });
    return response.status;
  },
  async updateOffer({ commit }, offer) {
    const response = await axios.post("/admin/offers/" + offer.get("id"), offer);
    if (response.data.success == "true")
      commit("UPDATE_OFFER", response.data.data);
    return response.data.message;
  },
  async deleteOffer({ commit }, id) {
    const response = await axios.post("/admin/offers/" + id,{"_method":"delete"});
    if (response.data.message == "deleted") {
      commit("DELETE_OFFER", id);
      return "deleted";
    } else return "failed";
  },
  setOffersCurrentPage({ commit }, currentPage) {
    commit("SET_OFFERS_CURRENT_PAGE", currentPage);
  },
  setOffersLastPage({ commit }, lastPage) {
    commit("SET_OFFERS_LAST_PAGE", lastPage);
  },
};
const mutations = {
  SET_OFFERS: (state, data) => (state.offers = data),
  SET_OFFERS_CURRENT_PAGE:(state, data) => (state.offers.current_page = data),
  SET_OFFERS_LAST_PAGE:(state, data) => (state.offers.last_page = data),
  DELETE_OFFER: (state, id) => {
    const i = state.offers.data.map(item => item.id).indexOf(id);
    state.offers.data.splice(i, 1);
  },
  UPDATE_OFFER: (state, data) => {
    const i = state.offers.data.map(item => item.id).indexOf(data.id);
    state.offers.data[i].title=data.title;
    state.offers.data[i].body=data.body;
    state.offers.data[i].from_date=data.from_date;
    state.offers.data[i].to_date=data.to_date;
    state.offers.data[i].discount_rate=data.discount_rate;
    state.offers.data[i].price=data.price;
    state.offers.data[i].images=data.images;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
