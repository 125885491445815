<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/v-slot-style -->
<template>
  <div class="login pt-10">
    <v-container class="fill-height">
      <v-card elevation="1" class="pa-10 mx-auto" width="90%">
        <v-row>
          <v-col cols="12" md="5">
            <h3 class="d-block d-md-none">
              طبيب تايم |
              <span class="deep-purple--text darken-1">DoctorTime</span>
            </h3>
            <br />
            <h1 class="mb-4 d-none d-md-block">تسجيل الدخول الى النظام</h1>
            <h5 class="mb-4 d-block d-md-none">
              تسجيل الدخول الى لوحة تحكم المدير
            </h5>
            <h2 class="mb-8 d-none d-md-block">Login to system</h2>

            <v-form ref="login_form" lazy-validation>
              <v-alert
                v-if="feedback"
                border="left"
                color="red lighten-2"
                dark
                >{{ feedback }}</v-alert
              >
              <v-text-field
                v-model="cred.username"
                :rules="requiredRule"
                label="اسم المستخدم"
                required
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="cred.password"
                :rules="requiredRule"
                label="كلمة السر"
                required
                class="mb-8"
                type="password"
                @keydown.enter="login()"
              ></v-text-field>

              <v-btn
                color="deep-purple darken-1  white--text"
                class="mb-3"
                @click="login()"
                :loading="loginLoading"
                :disabled="loginLoading"
                right
              >
                <v-icon>mdi-login</v-icon>
                تسجيل الدخول
              </v-btn>
            </v-form>
          </v-col>
          <v-col cols="7" class="d-none d-md-block">
            <img src="../../assets/imgs/doctor.svg" width="400" />

            <h2>
              طبيب تايم |
              <span class="deep-purple--text darken-1">DoctorTime</span>
            </h2>
            <h4>لوحة تحكم المدير</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginPage",
  data: function () {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],

      cred: {
        username: "",
        password: "",
      },
      feedback: "",
      timeout: null,
      loginLoading: false,
    };
  },
  mounted() {
    var token = localStorage.getItem("admin_token");
    if (token)
      this.attempt(token).then((res) => {
        if (res == "success") this.$router.push("/dashboard/main");
      });
  },
  methods: {
    ...mapActions(["loginFun", "attempt"]),

    login() {
      if (!this.$refs.login_form.validate()) return;
      this.loginLoading = true;
      this.feedback = "";
      this.loginFun(JSON.stringify(this.cred))
        .then((msg) => {
          if (msg == "success") {
            this.feedback = "";
            this.cred.username = "";
            this.cred.password = "";
            this.$router.push("/dashboard/main");
          } else if (msg == "unAuthorized") {
            this.feedback = "لاتمتلك صلاحية الدخول الى هذا المكان";
          } else if (msg == "incorrect") {
            this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
          } else if (msg == "failed" || msg == "error") {
            this.feedback = "حدث خطاء الرجاء اعادة المحاولة لاحقا";
          }
          this.loginLoading = false;
        })
        .catch((err) => {
          if (!err.response) {
            this.feedback = "يرجى التاكد من الاتصال بالانترنت!";
          } else if (
            err.response.status == 504 ||
            err.response.status == 500 ||
            err.response.status == 503 ||
            err.response.status == 501
          )
            this.feedback = "مشكلة في الخادم يرجى المحاولة لاحقا";
          else if (err.response.data.message == "invalid_username_or_password")
            this.feedback = "اسم مستخدم غير صحيح او كلمة مرور خاطئة";
          else this.feedback = "حدث خطاء الرجاء اعادة المحاولة لاحقا";
          this.loginLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: block;
  width: 100%;
  background: #e2e7f3;
  min-height: 100vh;
  text-align: center;
  direction: rtl;
}
</style>
