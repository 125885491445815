import axios from "@/axios";
const state = {
  notifications:[],
};

const getters = {
  allNotifications: state => state.notifications.data,
  getNotifCurrentPage: state => state.notifications.current_page,
  getNotifLastPage: state => state.notifications.last_page
};
const actions = {
  async fetchNotifications({ commit }, info) {
    const response = await axios.get(
      "/admin/notifications?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_NOTIFICATIONS", response.data);
  },
  async sendNewNotification(_, info) {
    const response = await axios.post("/admin/notifications", {
      title: info.title,
      body: info.body,
      type: info.type
    });
    return response.status;
  },
  async pushNotifications({ commit }, id) {
    const response = await axios.post("/admin/fcm/push/"+id);
    if(response.data==200)
    {
      commit("SET_NOTIFICATION_STATE",id);
      return "success";
    }
    else if(response.data=="404")
    return "noReceivers";
    else
    return "failed";
  },
  setNotifCurrentPage({ commit }, currentPage) {
    commit("SET_NOTIFICATIONS_CURRENT_PAGE", currentPage);
  },
  setNotifLastPage({ commit }, lastPage) {
    commit("SET_NOTIFICATIONS_LAST_PAGE", lastPage);
  },
};
const mutations = {
  SET_NOTIFICATIONS: (state, data) => (state.notifications = data),
  SET_NOTIFICATIONS_CURRENT_PAGE:(state, data) => (state.notifications.current_page = data),
  SET_NOTIFICATIONS_LAST_PAGE:(state, data) => (state.notifications.last_page = data),
  SET_NOTIFICATION_STATE:(state, id) => {
    const i = state.notifications.data.map(item => item.id).indexOf(id);
    state.notifications.data[i].is_pushed=1;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
