<template>
  <v-dialog v-model="clinicAssistantDialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="blue"
        style="letter-spacing: 0"
        class="float-right order-1 order-md-2 white--text ml-2 mb-2 d-inline-block"
      >
        <v-icon left dark>mdi-plus</v-icon>
        مساعد العيادة
      </v-btn>
    </template>
    <v-card v-click-outside="handleClickOutside">
      <v-card-title>
        <span class="headline" style="font-family: 'cairo' !important"
          >معلومات مساعد العيادة</span
        >
      </v-card-title>
      <v-card-text>
        <!-- Add your form fields for clinic assistant here -->
        <!-- Example: -->
        <v-container>
          <v-form
            ref="clinic_assistant_form"
            v-model="clinicAssistantValid"
            lazy-validation
          >
            <v-row>
              <!-- Add your form fields for clinic assistant here -->
              <!-- Example: -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="ClinicAssistant.fullname"
                  label="الاسم الكامل"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="ClinicAssistant.username"
                  label="اسم المستخدم"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="password"
                  v-model="ClinicAssistant.password"
                  label="كلمة السر"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="ClinicAssistant.gender"
                  :items="genders"
                  item-text="val"
                  item-value="id"
                  label="الجنس"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="tel"
                  v-model="ClinicAssistant.mobile"
                  label="رقم الهاتف"
                  :rules="mobileRule"
                  suffix="964+"
                  maxlength="10"
                  minlength="10"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="text"
                  v-model="ClinicAssistant.address"
                  label="العنوان"
                  :rules="requiredRule"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="11">
                <v-autocomplete
                  v-model="ClinicAssistant.clinic_id"
                  @keyup="searchForClinic"
                  :search-input.sync="searchClinic"
                  :items="clinicList"
                  item-value="id"
                  label="اختر العيادة"
                  placeholder="بحث عن اسم العيادة"
                  required
                  :rules="requiredRule"
                  outlined
                  :item-text="clinicFormatText"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Add your additional form fields for clinic assistant here -->
          </v-form>
          <v-row>
            <!-- Add your alerts and buttons here -->
            <!-- Example: -->
            <v-alert dense text type="success" v-if="successAlrt">
              عملية الاضافة <strong>تمت بنجاح</strong>
            </v-alert>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              فشل في عملية الاضافة الرجاء اعادة المحاولة
            </v-alert>
            <v-alert dense outlined type="warning" v-if="duplicateAlrt">
              اسم المستخدم محجوز يرجى اختيار اسم اخر
            </v-alert>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1 white--text"
          :loading="newClinicAssistantLoading"
          :disabled="newClinicAssistantLoading"
          @click="addClinicAssistant"
        >
          حفظ
        </v-btn>
        <v-btn color="black darken-1" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NewClinicAssistant",

  data() {
    return {
      // Define your data properties for clinic assistant here
      // Example:
      clinicAssistantDialog: false,
      clinicAssistantValid: false,
      newClinicAssistantLoading: false,
      genders: [
        { id: 1, val: "ذكر" },
        { id: 2, val: "انثى" },
      ],
      newClinicAssistant: new FormData(),
      ClinicAssistant: {
        clinic_id: 0,
        fullname: "",
        username: "",
        password: "",
        mobile: "",
        gender: 0,
        address: "",
      },
      searchClinic: "",
      // Add your existing data properties here if needed
      // Example:
      successAlrt: false,
      errorAlrt: false,
      duplicateAlrt: false,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
    };
  },
  computed: {
    ...mapGetters(["clinicList"]),
  },
  created() {
    // Fetch necessary data if needed
  },
  methods: {
    ...mapActions(["addNewClinicAssistant", "fetchClinicList"]),
    clinicFormatText: (item) => item.clinic_name + "-" + item.clinic_address,
    closeDialog() {
      // Reset your data properties here
      this.clinicAssistantDialog = false;
      this.newClinicAssistant = new FormData();
      // Reset additional properties if needed
      // Example:
      this.successAlrt = false;
      this.errorAlrt = false;
      this.duplicateAlrt = false;
      this.$refs.clinic_assistant_form.reset();
    },
    handleClickOutside(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    searchForClinic() {
      setTimeout(() => {
        if (this.searchClinic != "") this.fetchClinicList(this.searchClinic);
      }, 2000);
    },
    addClinicAssistant() {
      if (!this.$refs.clinic_assistant_form.validate()) return;
      this.newClinicAssistantLoading = true;
      this.duplicateAlrt = false;
      this.newClinicAssistant = new FormData();
      this.newClinicAssistant.append("fullname", this.ClinicAssistant.fullname);
      this.newClinicAssistant.append("username", this.ClinicAssistant.username);
      this.newClinicAssistant.append("password", this.ClinicAssistant.password);
      this.newClinicAssistant.append("mobile", this.ClinicAssistant.mobile);
      this.newClinicAssistant.append("address", this.ClinicAssistant.address);
      this.newClinicAssistant.append(
        "clinic_id",
        this.ClinicAssistant.clinic_id
      );
      this.newClinicAssistant.append("gender", this.ClinicAssistant.gender);

      this.addNewClinicAssistant(this.newClinicAssistant)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.newClinicAssistantLoading = false;

          this.ClinicAssistant.fullname = "";
          this.ClinicAssistant.username = "";
          this.ClinicAssistant.password = "";
          this.ClinicAssistant.mobile = "";
          this.ClinicAssistant.address = "";
          this.ClinicAssistant.gender = 0;
          this.ClinicAssistant.clinic_id = 0;
          this.$refs.clinic_assistant_form.reset();

          setTimeout(() => {
            this.successAlrt = false;
          }, 3500);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errors) {
            if (err.response.data.errors.username)
              if (err.response.data.errors.username[0])
                this.duplicateAlrt = true;
          } else this.errorAlrt = true;
          this.newClinicAssistantLoading = false;
        });
    },
  },
};
</script>
