//here are the routes of the vuex javascript
import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import clinic from "./modules/clinic";
import doctor from "./modules/doctor";
import patient_reservation from "./modules/patient_reservation";
import patients from "./modules/patients";
import counter from "./modules/counter";
import specialization from "./modules/specialization";
import notification from "./modules/notification";
import user from "./modules/user";
import offer from "./modules/offer";
import medical_advice from "./modules/medical_advice";
import offer_subscription from "./modules/offer_subscription";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    auth,
    clinic,
    doctor,
    patient_reservation,
    patients,
    counter,
    specialization,
    notification,
    user,
    offer,
    medical_advice,
    offer_subscription
  }
});
