<template>
  <div class="main" dir="rtl">
    <v-container fluid>
      <v-row class="mb-3">
        <v-col class="float-right pr-0" cols="12" sm="6" md="8">
          <div class="title mb-2" style="font-family: cairo!important;">
            احصائيات
          </div>
          <v-col
            cols="12"
            sm="12"
            md="5"
            v-for="card in statisticsCards"
            :key="card.title"
            class="pr-0 mx-right pt-3 mb-2 ml-2 float-right"
          >
            <v-card
              :to="card.route"
              class="mx-auto pa-3"
              color="white"
              max-width="280"
              outlined
            >
              <v-row class="pa-2">
                <v-col cols="12 mb-2">
                  <span
                    style="padding:8px;border-radius:6px;"
                    :class="card.color"
                  >
                    <v-icon x-large style="color:white;">{{
                      card.icon
                    }}</v-icon>
                  </span>
                </v-col>

                <v-col cols="12">
                  <div class="mb-2" style="font-weight:bold;">
                    {{ card.title }}
                  </div>
                  <div class="mb-3">
                    {{ getCounters[card.countName] }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-col>
        <!-- right side -->
        <v-col class="float-left" cols="12" sm="6" md="4">
          <div class="title mb-3" style="font-family: cairo!important;">
            وظائف سريعة
          </div>

          <v-col cols="12" sm="12" md="12" lg="10" class="pr-0 py-1 mb-4">
            <v-card color="indigo lighten-1 mb-4" max-width="250">
              <v-card-text class="px-3 py-2">
                <v-avatar color="indigo darken-3" size="40">
                  <v-icon size="large" color="grey lighten-5"
                    >mdi-bell-ring</v-icon
                  >
                </v-avatar>
                <span class="mr-2 white--text">الاشعارات</span>
                <v-btn
                  class="mt-2"
                  color="white  lighten-1 white--text"
                  elevation="0"
                  @click="NotificationsDialog = true"
                  text
                >
                  <v-icon left>mdi-send</v-icon>
                  ارسال اشعارات
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="float-right pr-0 py-1">
            <v-card color="red lighten-1 mb-4" max-width="250">
              <v-card-text class="px-3 py-2">
                <v-avatar color="red darken-3" size="40">
                  <v-icon size="large" color="grey lighten-5"
                    >mdi-credit-card-settings-outline</v-icon
                  >
                </v-avatar>
                <span class="mr-2 white--text">صفحة الاشتراكات</span>
                <v-btn
                  class="mt-2 d-block"
                  color="white  lighten-1 white--text"
                  elevation="0"
                  @click="showOffersSubscriptionsPage"
                  text
                >
                  <v-icon left>mdi-arrow-right</v-icon>
                  الاشتراكات
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
        <!-- left side -->
      </v-row>

      <!--  
			<div class="title mb-5" style="font-family: cairo!important;">
				الرسوم البيانية
			</div>
			<v-row>
				<v-col cols="12" md="6">
					<v-card class="mt-4 ml-auto" max-width="400">
						<v-sheet
							class="v-sheet--offset mx-auto"
							color="indigo lighten-2"
							elevation="12"
							max-width="calc(100% - 32px)"
						>
							<v-sparkline
								:labels="labels"
								:value="value"
								color="white"
								line-width="2"
								padding="16"
							></v-sparkline>
						</v-sheet>

						<v-card-text class="pt-0">
							<div class="text-h6 font-weight-light mb-2">
								مراجعين هذا الشهر
							</div>
							<div class="subheading font-weight-light grey--text">
								Last Update
							</div>
							<v-divider class="my-2"></v-divider>
							<v-icon class="mr-2" small>
								mdi-clock
							</v-icon>
							<span class="text-caption grey--text font-weight-light"
								>last registration 26 minutes ago</span
							>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6">
					<v-card class="mt-4 mx-auto" max-width="400">
						<v-sheet
							class="v-sheet--offset mx-auto"
							color="indigo lighten-1"
							elevation="12"
							max-width="calc(100% - 32px)"
						>
							<v-sparkline
								:labels="labels"
								:value="value"
								color="white"
								line-width="2"
								padding="16"
							></v-sparkline>
						</v-sheet>

						<v-card-text class="pt-0">
							<div class="text-h6 font-weight-light mb-2">
								عمليات التسجيل الجديدة
							</div>
							<div class="subheading font-weight-light grey--text">
								Last Update
							</div>
							<v-divider class="my-2"></v-divider>
							<v-icon class="mr-2" small>
								mdi-clock
							</v-icon>
							<span class="text-caption grey--text font-weight-light"
								>last registration 26 minutes ago</span
							>
						</v-card-text>
					</v-card>
				</v-col>

			<v-col cols="12" md="3">
					<h5 class="mb-4">
						<v-icon>mdi-doctor</v-icon> احصائيات العيادات حسب المحافظة
					</h5>
					<v-card outlined>
						<v-list>
							<v-list-item v-for="city in cities" :key="city.name">
								<v-list-item-avatar>
									<v-avatar color="indigo lighten-1" size="56">
										<span class="white--text">
											{{ city.name[0] }}
										</span>
									</v-avatar>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title>
										{{ city.name }} | {{ city.count }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col>

				<v-col cols="12" md="3">
					<h5 class="mb-4">
						<v-icon>mdi-account</v-icon> احصائيات المراجعين حسب المحافظة
					</h5>
					<v-card outlined>
						<v-list>
							<v-list-item v-for="city in cities" :key="city.name">
								<v-list-item-avatar>
									<v-avatar color="indigo lighten-1" size="56">
										<span class="white--text">
											{{ city.name[0] }}
										</span>
									</v-avatar>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title>
										{{ city.name }} | {{ city.count }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-col> -->
      <!-- 	</v-row> -->
    </v-container>
    <send-notifications
      :NotificationsDialog="NotificationsDialog"
      @notificationsDialogClose="NotificationsDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SendNotifications from "../../components/models/notifications/SendNotifications";

export default {
  name: "mainPage",
  components: { SendNotifications },

  computed: {
    ...mapGetters(["getCounters"]),
  },
  created() {
    this.fetchCounters();
  },
  methods: {
    ...mapActions(["fetchCounters"]),
    showOffersSubscriptionsPage() {
      this.$router.push({ name: "OffersSubscriptionsPage" });
    },
  },

  data() {
    return {
      NotificationsDialog: false,
      value: [423, 446, 675, 510, 590, 610, 760],
      search: "",
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      cities: [
        { name: "بصرة", count: 10 },
        { name: "بغداد", count: 20 },
        { name: "دهوك", count: 15 },
      ],
      statisticsCards: [
        {
          icon: "mdi-hospital-building",
          title: "عدد العيادات",
          countName: "clinics",
          color: "indigo lighten-2",
          iconColor: "indigo",
          route: "clinics",
        },
        {
          icon: "mdi-doctor",
          title: "عدد الاطباء",
          countName: "doctors",
          color: "green lighten-2",
          iconColor: "green",
          route: "doctors",
        },

        {
          icon: "mdi-account-multiple",
          title: "المراجعين",
          countName: "patients",
          color: "deep-orange lighten-2",
          iconColor: "deep-orange",
          route: "patients",
        },
        {
          icon: "mdi-list-status",
          title: "الزيارات المرضية",
          countName: "reservations",
          color: "blue lighten-2",
          iconColor: "deep-orange",
          route: "reservations",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  max-height: 82vh;
  overflow: auto;
  .notifications-card {
    float: right;
    background: white;
    border-radius: 5px;

    .icon {
      background: linear-gradient(45deg, #4884ee, #06bcfb) !important;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
